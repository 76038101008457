import React from 'react'
import Bkgnd from '../../components/bkgImg/Fondo2'
import Ventas from '../../components/Sales'

const Sales = () => {
  return (
    <Bkgnd>
        <Ventas/>
    </Bkgnd>
  )
}

export default Sales