import React from 'react'
import ChartInv from './Charts'
import './facturas.css'

const Invoice = () => {
  return (
    <div>
        <h1 className='MainLabels'>mi historial de compras</h1>
        <ChartInv/>
    </div>
  )
}

export default Invoice