import React, {useState} from 'react'
import { Button, Modal, Row,Table,Col, InputGroup, Form, Container } from 'react-bootstrap'
import "./styleModal.css"
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'

const AnsQuote = (props) => {
   
    const [show, setShow] = useState(true);
    const [folioMantic,setFolioMantic]=useState("");
    const [quoteValue,setQuoteValue]=useState(0)
    const {updateOpenRfqs,setUpdateOpenRfq}=useAuth()

    const HandleUpdateQuote=async(ID)=>{
        console.log(ID);
        let date= new Date();
        console.log(date);
        const payload = {
            id:ID,
            Value:quoteValue,
            Folio:folioMantic,
            CloseStatus:true,
       
        }

        await ApiCall.UpdateQuote(payload)
        .then(res=>console.log(res))
        .catch(e=>console.log(e));
        await setUpdateOpenRfq(!updateOpenRfqs)

    }

  return (
    <div>
        <Modal contentClassName='ModStyle' show={show} fullscreen={true} onHide={()=>setShow(!show)} onExited={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{color:'#FAB771'}}>Cotización # {props.data._id.substr(-5,5)}  </Modal.Title>
            </Modal.Header>
                
            <Modal.Body>
                <Table responsive variant='dark'>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Descripción</th>
                            <th>Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.data.content.map((item)=>(
                    <tr>
                    <th><img src={item.Picture} alt={item.Name} 
                            style={{
                                     // borderRadius:'50%',
                                     width:'30px',
                                     height:'30px',
                                     objectFit:'cover',
                                     fontSize:'04pt'
                             }} /></th>
                    <th style={{fontSize:'08pt'}}>{item.Brand}  {item.Name}</th>
                    <th>{item.qty}</th>
                    </tr>
          ))}

                    </tbody>

                </Table>
            
            </Modal.Body>
            <Modal.Footer>
                <Container>

               
                <Row>
                    <Col md={4}>
                        <Form.Group>
                        <Form.Label>Folio Mantic:</Form.Label>
                        <InputGroup>
                        <Form.Control type='text' value={folioMantic} onChange={(e)=>setFolioMantic(e.target.value)}></Form.Control>
                        </InputGroup>
                             
                        </Form.Group>
                        
                       
                     
                    </Col>
                    <Col md={4}>
                    <Form.Group>
                        <Form.Label>Valor de Cotización: $</Form.Label>
                        <InputGroup>
                        <Form.Control type='text' value={quoteValue} onChange={(e)=>setQuoteValue(e.target.value)}></Form.Control>
                        </InputGroup>
                             
                        </Form.Group>
                    </Col>
                    
                        
                  
                </Row>
                </Container>
                <Button className='m-4' variant='warning' style={{backgroundColor:'#FAB771'}} onClick={()=>HandleUpdateQuote(props.data._id)}>Actualiza Cotización</Button>
                <Button onClick={()=>setShow(!show)}> Exit</Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default AnsQuote