import React, {useState,useEffect} from 'react';
import { Button, Modal, Container, Table } from 'react-bootstrap';
import ApiCall from '../../AxiosCalls';
import useAuth from '../Hooks/useAuth';
import AnsQuote from './AnsQuote';
import "./styleModal.css"

function MyVerticallyCenteredModal(props) { 
  const {singleQuote,setSingleQuote}=useAuth()
  const [selected,setSelected]=useState([])

  useEffect (()=>{
  },[singleQuote])

  const HandleReview=async(QuoteId)=>{
    let SelectedQuote= await ApiCall.QuoteByID(QuoteId)
    .then(res=>res.data)
    .catch(e=>console.log(e))
    setSelected(SelectedQuote)
    await setSingleQuote(!singleQuote)
    
  }


  return (      

    <div>
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'#FAB771'}}>
          Cotizaciones Abiertas: <span className='ms-2'> {props.uniquequote.length}</span>  
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table responsive className="table table-dark table-hover">
          <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cliente</th>
          <th scope="col">vendedor</th>
          <th scope="col">Fecha (dd/mm/yyyy)</th>
          <th scope='col'>Contestar</th>
        </tr>
      </thead>
      <tbody>
         {props.uniquequote.map((quote)=>{
          let newDate=new Date(quote.raw.Start);
          let myDate=newDate.toLocaleDateString('es-MX') // Converts date format to spanish for mexico region
          return(
            <tr key={quote.raw._id}>
              <th scope='row'>{quote.raw._id.substr(-5,5)}</th>
              <td>{quote.ClientFirst} {quote.ClientLast}</td>
              <td>{quote.VendorFirst} {quote.VendorLast}</td>
              <td>{myDate}</td>
              <td> <i onClick={()=>HandleReview(quote.raw._id)} className="fa-duotone fa-clipboard-check"></i></td>
            </tr>
          )}   
        )}
      </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
      <Container className='d-flex justify-content-center'>
        {singleQuote && (<AnsQuote onClose={()=>setSingleQuote(!singleQuote)} data={selected} />)}
      
        <Button variant='secondary' onClick={props.onHide}>Cerrar Ventana</Button>
        </Container>
      </Modal.Footer>
      
    </Modal>
    
    </div>
  );
}

const RFQbyUSER=(props)=> {
  const [modalShow, setModalShow] = useState(true);// change to false when its button activated 
  const {showQuotes,setShowQuotes,singleQuote}=useAuth()
  const [uniQuote,setUniQuote]=useState([])

  useEffect(()=>{
     // loading all active quotes and reorganize them per user

    setUniQuote([]);
    const MakeArray=async()=>{  
      let AllRfQs= await ApiCall.AllQuotes()
      .then(res=>res.data)
      .catch(e=>console.log(e))
      let ThisUserQuotes=AllRfQs.filter(q=> q.UserID === props.client &&  q.CloseStatus === false)
      let [usr,vdr]=[{},{}]
     ThisUserQuotes.map(async(quote)=>{
          quote.UserID ?
          ( await ApiCall.FindByID(quote.UserID)
            .then(res=>{usr=res.data})
            .catch(e=>console.log(e))  
          ):( usr={})
          quote.SellerID ?
          ( await ApiCall.FindByID(quote.SellerID)
            .then(res=>{vdr=res.data})
            .catch(e=>console.log(e))
          ):(vdr={})
          let ResObj={raw:quote,
            ClientFirst:usr.FirstName,
            ClientLast:usr.LastName,
            VendorFirst:vdr.FirstName,
            VendorLast:vdr.LastName}
            setUniQuote((uniQuote)=>[...uniQuote,ResObj])
        } ) }
    MakeArray();
  },[singleQuote,props.client])

  return (
 
    <>
      <MyVerticallyCenteredModal
        uniquequote={uniQuote}
        show={modalShow}
        usuario={props.client}
        onHide={() => {setModalShow(false);
          setShowQuotes(!showQuotes)
        
    }
        }
      />
    </>
  )
}

export default RFQbyUSER;