import React from 'react'
import { Form,Container,Button } from 'react-bootstrap'
import '../LogFrm/style.css'
import useAuth from '../Hooks/useAuth'
import ApiCall from '../../AxiosCalls'


const TicketFrm = () => {
const {ticketState,ticketDispatch}=useAuth()

  const HandleOnChange=(e)=>{
    e.preventDefault();
    ticketDispatch({
      type:"ADD_TICKET",
      payload:{
        input:e.target.name,
        value:e.target.value
      }
    })
  }

  const HandleSubmit=async(e)=>{
    e.preventDefault();
    //auxiliary data 
    const day=Date();
    let payload=ticketState;
     payload= {...payload,CloseStatus:false,Start:day};
  try{
       await ApiCall.CreateTicket(payload)
      .then(res=>{
        let caseNum=res.data._id.substr(-5,5)
        alert("El Ticket #: "+caseNum+" ha sido creado!" +
        "\nUno de nuestros expertos se pondrá en contacto contigo.")
        e.stopPropagation();
        e.target.reset(); // to reset the form after submission 
      })
    }catch (err){
      console.log(err);
    }
   
   }

  return (
    <Container className='masthead color-overlay  justify-content-center align-items-center'>
    <Form className="rounded p-4 p-sm-3" id='myForm' onSubmit={(e)=>HandleSubmit(e)}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label>Nombre</Form.Label>
    <Form.Control type="name" placeholder="John Doe" name="UserName" onChange={(e)=>{HandleOnChange(e)}}/>
  </Form.Group>
  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label>Número de WhatsApp</Form.Label>
    <Form.Control type="phone" placeholder="+52 435 439 5355" name="WhatsappNum" onChange={(e)=>{HandleOnChange(e)}} />
  </Form.Group>
  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label>Correo Electrónico</Form.Label>
    <Form.Control type="email" placeholder="nombre@ejemplo.com" name="Email" onChange={(e)=>{HandleOnChange(e)}} />
  </Form.Group>
  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Describenos tu pregunta</Form.Label>
    <Form.Control as="textarea" rows={3} name="Content" onChange={(e)=>{HandleOnChange(e)}} />
  </Form.Group>
  <Button variant='secondary' type='submit'>Enviar</Button>
</Form>
    </Container>
  )
}

export default TicketFrm