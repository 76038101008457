import React, {useState,useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import useAuth from './Hooks/useAuth'
import useRefreshToken from './Hooks/useRefreshToken'

const PersistentLogin = () => {
    const [isLoading,setIsLoading]=useState(true)
    const{auth,persist}=useAuth()
    const refresh=useRefreshToken()

useEffect (()=>{
    //need to add the is mounted variable to prevent memory leaks when updating the states before the component mounts
    let isMounted = true
const verifyRefreshToken=async()=>{
    try{
        await refresh();
    }
    catch(err){
        // console.error(err)
    }
    finally{
       isMounted && setIsLoading(false)
    }

}

!auth.accessToken ? verifyRefreshToken() : setIsLoading(false)

//need a cleanup function to secure the component is unmounted
return ()=> isMounted = false;

},[])



//just for debugging
useEffect(()=>{
    // console.log(`isLoading: ${isLoading}`);
    // console.log(`token: ${JSON.stringify(auth?.accessToken)}`)
},[isLoading])


  return (
    <div>
        {
        !persist // if persist is not present then just go and show the outlet 
         ?   <Outlet/>
        : isLoading
            ? <p>Loading ...</p>
             :<Outlet/>
        }
    </div>
  )
}

export default PersistentLogin