import React from 'react'
import { Container } from 'react-bootstrap'
import Brands from "../images/MainPage/MarcasBanner2.png"



const Brand = () => {
  return (
 
<Container fluid className='my-5'>
<img  className="img-fluid" src={Brands} alt="logo"></img>
</Container>


    

  )
}

export default Brand