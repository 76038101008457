import React, {useState,useEffect} from 'react'
import './style.css'
import { Col, Nav,Card,Row, Button,Dropdown,Container} from 'react-bootstrap'
import useAuth from '../../components/Hooks/useAuth'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'
import Invoice from '../../components/Facturas'
import IdCard from '../../components/Card/Id'
import Support from '../support'
import Sticky from 'react-stickynode'
import ApiCall from '../../AxiosCalls'
import { useNavigate } from 'react-router-dom'
import MyQuotes from '../../components/QuotesMY'

const Supply = () => {
  const {auth,products,cartState:{cart},cartDispatch}=useAuth();
  const [singleUser,setSingleUser]=useState({})
  const [quotesFromUser,setQuotesFromUser]=useState([])
  const [target, setTarget] = useState(null);
  const [vendor,setVendor]=useState("");
  const [showQuotes,setShowQuotes]=useState(false)
  const [refresh,setRefresh]= useState(false);
  const [categories,setCategories]=useState([])
  const [toFilter,setTofilter]=useState(null)
const Navigate=useNavigate();

useEffect(()=>{
  getUSerInfo();
  retriveQuotes();
  CategoryFilter();

},[refresh])

const CategoryFilter=()=>{
  let CatArray=products.map(p=> p.Category);
  let NewCatArry=[...new Set(CatArray)]
  setCategories(NewCatArry)
}

const getUSerInfo= async()=>{
  const userinfo= await ApiCall.FindByID(auth.user._id)
  .then(res=>res.data)
  .catch(e=>console.log(e))
  setSingleUser(userinfo);
  const Vendedor= await ApiCall.FindByID(userinfo.SellerID)
  setVendor(Vendedor.data.FirstName+" "+Vendedor.data.LastName)
}

const retriveQuotes=async()=>{
  const quotes= await ApiCall.AllQuotes()
  .then(res=>res.data)
  .catch(e=>console.log(e))
  let meinquote= quotes.filter(x=>x.UserID === auth.user._id)
  setQuotesFromUser(meinquote)

}
  

const HandleCart=(Id)=>{
  let filtering=products.filter(product=>product._id === Id._id)
// this auxiliary array is needed since we are getting only one item from the DB 
  let toCart=filtering[0] 
console.log(toCart._id, toCart.Brand, toCart.Name);

  cartDispatch({
    type:"ADD_PRODUCT",
    payload:{
      Id:toCart._id,
      Brand:toCart.Brand,
      Name:toCart.Name,
      Picture:toCart.Picture
    }
  })
}
const ClearCart=()=>{
  cartDispatch({type:"EMPTY_CART"})
}
const HandleRemove=(itemID)=>{
  cartDispatch({
    type:"REMOVE_PRODUCT",
    payload:{
      Id:itemID
    }
  })

}

const HandleRFQ=()=>{
  let day=Date()
  if (cart.length < 1) {
    alert("Tu Carrito Esta Vacío.. Agrega Productos")}
    else{
  const payload={
    content:cart, 
    UserID:auth.user._id,
    SellerID:singleUser.SellerID,
    CloseStatus:false,
    Start:day
  }
  ApiCall.AddRFQ(payload)
  .then(res=>{
    (res.status===200)?(alert("Tu requerimiento de cotizacion se ha enviado a tu representante de ventas")):(alert("Se perdio la conexión de internet, por favor intenta de nuevo más tarde"))
  })
  .catch(err=>console.log(err));
}

  ClearCart();
  
  setRefresh(!refresh);
}

const SelectedCategory=()=>{
  let chosen=products.filter(product=> product.Category === toFilter)
  return chosen
}

  return (
    <div>
     <Header/>
     <NavBar/>
     <Container fluid className='mb-4'>  
    <Row >  
    <Col md={2}  className='VerOpt'>    
        <Nav defaultActiveKey="/home" className="NavTitles flex-column">
          <Nav.Link href={"#"} eventKey="link-1" onClick={(e)=>{
            setShowQuotes(!showQuotes);
            setTarget(e.target);
          }} style={{fontSize:'12pt'}}>Mis Cotizaciones: <span> {quotesFromUser.length}</span> </Nav.Link>
          <Nav.Link eventKey="link-2" style={{fontSize:'12pt'}}>Mis facturas</Nav.Link>
          <Nav.Link eventKey="link-2" style={{fontSize:'12pt'}}>Mis Envíos</Nav.Link>
          <Nav.Link href="/home" style={{fontSize:'12pt'}}>Inicio</Nav.Link>
        </Nav>
        {showQuotes && <MyQuotes target={target} quotes={quotesFromUser}/>}
        <Row className='ms-3'>
          <Col className='mt-5'>
            <h1 className='LegendMonedero'> monedero acumulado por tus compras</h1>
            <h2 className='my-4'>$5,000.00</h2>
            <Button className='Monederobtn' style={{ textTransform:'uppercase',backgroundColor:'#174471',height:'4rem'}}>canjear</Button>
            <div>&nbsp;</div>
            <Button className='Monederobtn mt-4' onClick={(e)=>{e.preventDefault();HandleRFQ()}} style={{ textTransform:'uppercase',backgroundColor:'#174471',marginBottom:'5rem'}}>pedir cotización</Button>
          </Col>
        </Row>       
      </Col>  
      <Col md={10}>
        <Container fluid className='pb-4'>
        <Row className='mb-2'>
        <Col xl={7} className='MainLabels ms-3'>
          <Invoice/> 
        </Col>
        <Col xl={3} className='Cust-info'>
          <IdCard user={singleUser} vendor={vendor}/> 
        </Col>
        </Row>
        </Container>
        </Col>
        </Row>
        </Container>     
  <Row className='mb-4'>

    <Col md={2}>
      <Sticky  enabled={true} top={50} className='mt-5'
      >
      <div className="cart-container">
         <Dropdown className='text-start ps-2' >
      <Dropdown.Toggle style={{backgroundColor:'transparent', color:'#174471', border:'none'}} id="dropdown-basic">
      
      <div className="d-flex align-items-center">
      <i className="fa-duotone fa-cart-shopping fa-2xl" style={{color:"#174471", marginBottom: '30px'}}></i>
      <span className='ps-2' style={{fontSize:'16pt'}}>{cart.length}</span>
      
      </div>
  
      </Dropdown.Toggle>

      <Dropdown.Menu style={{minWidth:'17rem'}}>
        
        {!(cart.length)?(<span className='p-2' style={{color:'red'}} >Agrega algunos productos!</span>):(
        <>
          {cart.map((item)=>(
            <span style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0 20px'}}>
              <img src={item.Picture} alt={item.Name} 
              style={{
                // borderRadius:'50%',
                width:'30px',
                height:'30px',
                objectFit:'cover'
              }} />
              <div style={{display:'flex',flex:'1', padding:'0 40px', flexDirection:'column',fontSize:'10pt'}}>
                <span>{item.Brand}</span>
                <span>{item.Name}</span>
                </div>
                <span className='ListItem'>
              <a href='#' onClick={(e)=>{e.preventDefault();HandleRemove(item.Id)}}>
              <i className="fa-duotone fa-trash-can"></i></a>
              </span>
            </span>
          ))}
          <Dropdown.Divider />
      <Dropdown.Item eventKey="1" style={{textTransform:"uppercase", color:"#174471"}} onClick={(e)=>{e.preventDefault();HandleRFQ()}}>Pedir cotización</Dropdown.Item>
            </>
      )}
  
      </Dropdown.Menu>
      </Dropdown>
      </div>
      </Sticky>


    </Col>
    <Col md={10} style={{ marginTop:'-7rem'}}>
       
        <Row className='CardGrid ms-2 mt-5'> 
          <h1 className='Mainlabels'>Explora nuestros productos</h1>
        <Container className='mb-2'>
          {
            categories.map((c)=>(
              <Button  className='ms-1 me-1 mb-2' key={c._id} 
              value={c} style={{backgroundColor:'#174471', borderRadius:'10rem'}} 
              onClick={(e)=>setTofilter(e.target.value)} >{c}</Button>
            ))}


        </Container>

 {
    toFilter && SelectedCategory().map(({_id,Category,Brand,Name,Datasheet,Picture,DescripcionCorta})=>{
      return(
        <Card style={{ 
          width: '14rem',
          marginBottom:'1.5rem'
          }} key={_id}>
    <Card.Body>
      <Card.Title>{Brand}</Card.Title>
      <Card.Img variant="top" style={{ height:'4rem', width:'auto'}} src={Picture} />
      <Card.Subtitle className="mb-2 text-muted">{Name}</Card.Subtitle>
      <Card.Text>
      {Category}
        <br/>       
        {DescripcionCorta}
      </Card.Text>
    </Card.Body>
    <Card.Footer style={{backgroundColor:"transparent"}}>
      <Button style={{backgroundColor:'#004aad', textTransform:'uppercase'}}
      onClick={()=>HandleCart({_id})}
      >agregar</Button>
    </Card.Footer>
  </Card>
      ) 
    }
    )
 }




    
      </Row>    
      </Col>
  </Row> 
  <Support/>
    </div>
  )
}

export default Supply