import React, {useState,useEffect} from 'react';
import { Button, Modal, Form, Row,Col } from 'react-bootstrap';
import useAuth from '../Hooks/useAuth';
import ApiCall from '../../AxiosCalls';
import "./styleModal.css"

function MyVerticallyCenteredModal(props) { 
  const [passed,setPassed]=useState([])
  const [vendors,setVendors]=useState([])
  const [vendorID,setVendorID]=useState("")
  const [profile,setProfile]=useState("");
  const [refresh,setRefresh]=useState(false)

  useEffect(()=>{
    setPassed(props.passuser)
    setVendors(props.vendedores)
  },[props.passuser,refresh]) 

 const handleUpadte=async()=>{
  let x = {id:passed._id,SellerID:vendorID}
  await ApiCall.UpdateUser(x)
  .then(res=>{ (res.status === 200) && alert("Perfil de usuario cambió a:  " + profile)})
  .catch(e=>console.log(e));
  setRefresh(!refresh)
  
 }

  return (
        
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cliente  :   {passed? (<>{passed.FirstName} {passed.LastName}</>):(<></>)}    
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    
        <Row className='mt-5'>
          <Col md={4}>
          <h4 style={{fontSize:'14pt',}}>Asignar Vendedor:</h4>

          </Col>
          <Col md={4}>
           <Form.Select 
           id="selectProfile"
           className=""
           aria-label='SelectNewSalesPerson'
           onChange={(e) => setVendorID(e.target.value)}
           >
            <option>Selecciona un Vendedor</option>
             {vendors ? (vendors.map(vendor=>(<option key={vendor._id} value={vendor._id}>{vendor.FirstName} {vendor.LastName}</option>))):(<></>)}
           </Form.Select>     
          </Col>
        </Row>   
      </Modal.Body>
      <Modal.Footer>
      <Button variant='secondary' onClick={props.onHide} className='position-absolute start-0 ms-3'>Todo Listo... Cerrar Ventana</Button>
        <Button onClick={()=>handleUpadte()}>Salvar Cambios</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ClientMod=(props)=> {
  const [singleUser,setSingleUser]=useState([])
  const [modalShow, setModalShow] = useState(true);// change to false when its button activated
  const {editVendor,setEditVendor}=useAuth();

useEffect(()=>{
  const CallDb=async()=>{
    const items= await ApiCall.FindByID(props.User)
    setSingleUser(items.data)
}
CallDb();
},[props.User])
  return (
    <>
      <MyVerticallyCenteredModal
        passuser={singleUser}
        vendedores={props.vendors}
        show={modalShow}
        onHide={() => {setModalShow(false);
          setEditVendor(!editVendor)
             
    }
        }
      />
    </>
  );
}



export default ClientMod;