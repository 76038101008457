import React from 'react'
import Bkgnd1 from '../../components/bkgImg/FondoSetting'
import CustomLayout from '../../components/Costumization'

const MainUpdate = () => {
  return (
    <Bkgnd1>
        <CustomLayout/>
    </Bkgnd1>
  )
}

export default MainUpdate