import React from 'react'
import { Card } from 'react-bootstrap'
import '../Facturas/facturas.css'

const IdCard = (props) => {
  return (
    <Card className="CardId">
        <Card.Body>
       
        <Card.Text>
          <h1>Razón Social:</h1>
          <h4>{props.user.FirstName} {props.user.LastName}</h4>
          <br/>
          <h1>Rfc:</h1>
          <h4>VENJ221225AC4</h4>
          <br/>
          <h1>Mi Contacto:</h1>
          <h4>{props.vendor}</h4>
          

        </Card.Text>
       
      </Card.Body>
    </Card>
  )
}

export default IdCard