import React from 'react'
import Fondo from '../../components/bkgImg'
import CardReg from '../../components/Card/RegCard'
import RegFrm from '../../components/RegFrm'

const Registro = () => {
  return (
    <Fondo>
      <div className='sign_form'>
        <CardReg>
          <RegFrm/>
        </CardReg>
      </div>
    </Fondo>
  )
}

export default Registro