import React,{useState} from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'
import ProductShow from '../ProductDetail';
import { Link } from 'react-router-dom';

const CardProd = (props) => {
  const {cartDispatch,auth, persist,
    products,productDet, setProductDet,singleProd,setSingleProd}=useAuth(); 


  const HandleClick=(e)=>{
    e.preventDefault();
    setProductDet(!productDet)
   setSingleProd({...singleProd, product:props}) //constructor containing an array called "product"
    
  }

  const HandleAsk=(e)=>{
    e.preventDefault()
    alert("Por favor Inicia sesión para agregar productos al carrito")
  }
 

  const HandleCart=(Id)=>{
    let filtering=products.filter(product=>product._id === Id)
  // this auxiliary array is needed since we are getting only one item from the DB 
    let toCart=filtering[0] 
  console.log(toCart._id, toCart.Brand, toCart.Name);
  
    cartDispatch({
      type:"ADD_PRODUCT",
      payload:{
        Id:toCart._id,
        Brand:toCart.Brand,
        Name:toCart.Name,
        Picture:toCart.Picture
      }
    })
  }


  return (
   
        <Card style={{width: '13rem', margin:'0px 20px 55px 0px'}}>
            <Card.Body>
                <Card.Title>{props.marca}</Card.Title>
                <Card.Img variant='top' style={{ height:'4rem', width:'auto'}} src={props.image} />
                <Card.Subtitle className='mt-2'>{props.category}<br/>{props.pdctName}</Card.Subtitle>
              
                
            </Card.Body>
            <Card.Footer className='cardFoot mb-0'>
                    
                    <span className='me-3 ms-1'>
                    <a href onClick={(e)=>HandleClick(e)}><i class="fa-duotone fa-file-arrow-down fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#23429f"}} ></i></a>
                    </span>
                    <span>
                    <a href onClick={auth?.isAuthenticated || persist
                      ? ((e)=>HandleCart(props.Key)):((e)=>HandleAsk(e))}><i class="fa-duotone fa-cart-plus fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#f2a854"}} ></i></a>
                    </span>
                    {productDet && (<ProductShow key={props.Key} product={props}/>)}
                </Card.Footer>

        </Card>
    
  )
}

export default CardProd