import React, {useState,useEffect} from 'react';
import { Button, Modal, Container, Table } from 'react-bootstrap';

import useAuth from '../Hooks/useAuth';
import "./styleModal.css"
import ApiCall from '../../AxiosCalls';

function MyVerticallyCenteredModal(props) { 
 

  // const {rfq}=useAuth();
  
  useEffect(()=>{
  // loading all active quotes and reorganize them per user

  },[]) 

  



  return (      
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'#FAB771'}}>
          Cotizaciones Abiertas: {props.quotes.length}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table responsive className="table table-dark table-hover">
          <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cliente</th>
          <th scope="col">vendedor</th>
          <th scope="col">Fecha (dd/mm/aaaa)</th>
        </tr>
      </thead>
      <tbody>
         {props.quotes.map((quote)=> {
        let newdate= new Date(quote.raw.Start)
        let myDate= newdate.toLocaleDateString('es-MX') // converts to local spanish for Mexico date DD/MM/YYYY     
          return(
            <tr key={quote.raw._id}>
            <th scope='row'>{quote.raw._id.substr(-5,5)}</th>
            <td>{quote.ClientFirst} {quote.ClientLast}</td>
            <td>{quote.VendorFirst} {quote.VendorLast}</td>
            <td>{myDate}</td>
          </tr>
          )}
        )}
      </tbody>
   
      </Table>
      </Modal.Body>
      <Modal.Footer>
      <Container className='d-flex justify-content-center'>
        <Button variant='secondary' onClick={props.onHide}>Cerrar Ventana</Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

const ShowRFQ=(props)=> {
  const {showQuotes,setShowQuotes}=useAuth()
  const [modalShow, setModalShow] = useState(true);// change to false when its button activated 
  const [openQuotes,setOpenQuotes]=useState([]);

useEffect(()=>{
  setOpenQuotes([]);
   const MakeArray=async()=>{  
   await Promise.all(props.quotes.map(async(qt)=>{
       let usr= await ApiCall.FindByID(qt.UserID)
        .then(res=>res.data)
        .catch(e=>console.log(e))
       let vdr= await ApiCall.FindByID(qt.SellerID)
          .then(res=>res.data)
          .catch(e=>console.log(e))
        let ResObj={raw:qt,
          ClientFirst:usr.FirstName,
          ClientLast:usr.LastName,
          VendorFirst:vdr.FirstName,
          VendorLast:vdr.LastName}
          setOpenQuotes(openQuotes=>[...openQuotes,ResObj])
      }))}
  MakeArray();
},[])

  


  return (
    <>
      <MyVerticallyCenteredModal
        quotes={openQuotes}
        show={modalShow}
        onHide={() => {setModalShow(false);
          setShowQuotes(!showQuotes) // this avoids false clicks since we are using context
          
    }
        }
      />
    </>
  );
}



export default ShowRFQ;