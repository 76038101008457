import React, {useState,useRef} from 'react'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

const MyQuotes = (props) => {
    const [show,setShow]=useState(true)
    const ref = useRef(null);

    const ToCurrency =(e)=>{
      const formater= 
      new Intl.NumberFormat('en-us',{
        style:'currency',
        currency:'USD'
      })
      return formater.format(e)
    }

  return (
    <div ref={ref} style={{width:'20rem',backgroundColor:'pink'}}>
    
      <Overlay
        show={show}
        target={props.target} // i linked it to the line that sets the command on the previous component 
        placement="right"
        container={ref}
        containerPadding={20}
        
      >
        <Popover id="popover-contained" style={{maxWidth:'105rem',backgroundColor:'#174471'}}>
          <Popover.Header as="h3">Mi historial de cotizaciones:</Popover.Header>
          <Popover.Body>
          <table className="table table-dark table-hover" style={{width:'40rem'}}>
          <thead>
        <tr>
          <th scope="col">ID #</th>
          <th scope="col">Articulos</th>
          <th scope="col">Fecha <br></br>(aaaa-mm-dd)</th>
          <th scope="col">Estatus</th>
          <th scope='col'> Cotizado</th>
        </tr>
      </thead>
      <tbody>
         {props.quotes.map((quote)=>
            <tr key={quote._id}>
              <th scope='row'>{quote._id.substr(-5,5)}</th>
              <td>{quote.content.length}</td>            
              <td>{quote.Start.substr(0,10)}</td>
              <td>{(quote.CloseStatus === false) ? ("Abierto"):("Cerrado")}</td>
              <td>{(quote.CloseStatus === false) ? ("En Espera"):(ToCurrency(quote.Value))}</td>
            </tr>
        ) }
        
        
      </tbody>
   
      </table>
            <Container className='d-flex justify-content-center mt-2'>
            <Button variant='dark' onClick={()=>setShow(!show)}>Cerrar Ventana</Button>
            </Container>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
    
  )
}

export default MyQuotes