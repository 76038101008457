
import React, {useEffect, useRef, useState} from "react";
import {Form, Button, Container,Row, InputGroup} from "react-bootstrap"
import "../LogFrm/style.css"
import { useNavigate, useLocation} from "react-router-dom";

const NoAuthorized = () => {
    
    const navigate = useNavigate();
    // the following two lines must be used when we need to redirect to a specific page
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";
        useEffect(()=>{
        },[])

        const HandleSubmit = ()=>{
            navigate(-1)
        }
       
      return (
        <div className="masthead">
        <div className="color-overlay d-flex justify-content-center align-items-center">
         <Form className="rounded p-4 p-sm-3" onSubmit={HandleSubmit} >
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                     <Form.Label style={{fontSize:'16pt'}}>No Estás Autorizado </Form.Label>              
                 </Form.Group>  
                 <div className="d-grid gap-2">
                     <Button variant="secondary" size="lg" type="submit" >
                          Regresar
                     </Button> 
                 </div>
      </Form>
     </div>
    
     <Row >
         <Container className="py-4">
             <section>No tienes tu cuenta? </section>
             <span><Button variant="outline-secondary" onClick={()=>navigate("/registro",{replace:true})} >Registrate!</Button></span>
         </Container>
     </Row>
    </div>
      )
}

export default NoAuthorized