import React, {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row,Container, Card, Button,Form} from 'react-bootstrap'
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'
import PosterUpdate from '../PosterUpdate'



const Marketing = () => {
    const Navigate=useNavigate();
    const [picture,setPicture]=useState("")
    const [category,setCategory]=useState("")
    const [productID,setProductID]=useState("");  
    const [selectedBrand,setSelectedBrand]=useState("")
    const [titulopromo,setTitulopromo]=useState("")
    const [descripcion,setDescripcion]=useState("")
    const [promoCategory,setPromoCategory]=useState("");
    const {products}=useAuth()
    
    useEffect(()=>{  
    },[])  
// declaring all filters
    const ItemsCat=products.map(product=>product.Category)
    const ProductLine=[...new Set(ItemsCat)]
   const PromoCategories=["Promociones activas", "Lo más vendido", "Lo más buscado"]

   const GetBrandArry=()=>{
    //filter only by selected category 
    const BrandArry = products.filter(x=>x.Category === category)
     let TempBrand= BrandArry.map(product=>product.Brand)
   let NewBrands=[...new Set(TempBrand)]
    return NewBrands
}

//function to filter and products once the Category and Brands are selected 
const GetProductArray=()=>{
    let ProductFilter= products.filter(b=>b.Category === category && b.Brand === selectedBrand)
    return ProductFilter        
}

//function to transform the file on 64 format 
const TransformTo64=(e)=>{
    let reader= new FileReader();
    reader.readAsDataURL(e.target.files[0])
    reader.onload = ()=>{
        console.log(reader.result);  // here it is our sting base64
        setPicture(reader.result) //this saves it on the state function 
    }
    reader.onerror= e=>{console.log(e)}
}

//function to add/update/create a promotion 
const HandleCreatePromo=async()=>{
    const Payload={
        ProductREFid:productID,
        PromoTitle:titulopromo,
        PromoCategory:promoCategory,
        PromoImage:picture,
        PromoDesc:descripcion
       
    }
    
    await ApiCall.AddPromo(Payload)
    .then(res=> res.status===200 && alert("Promoción Guardada Exitosamente!"))
    .catch(e=>{
        console.log(e);
        alert("No fue posible crear la promoción, verifica que los datos estén correctos")
    })

}
  return (
    <div>
         <Row>
        <Container fluid className='mt-2' style={{fontSize:'16pt',fontWeight:'bold'}}> Bienvenido a tu Herramienta de Marketing</Container>
      </Row>
      <Row>
        <Container style={{backgroundColor:'transparent'}} className=" mt-5 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Agregar una promoción</Card.Title>
            <Card.Body>
            <Container className='pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Título de la promoción</Form.Label>
        <Form.Control as="textarea" rows={1} onChange={(e)=>setTitulopromo(e.target.value)}/>
      </Form.Group>
          </Container>
          
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectProductLine"        
         onChange={(e)=>{setCategory(e.target.value);
         }}
          >
            <option>línea de producto</option>
       {ProductLine.map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAbrand"        
          onChange={(e) => {
            setSelectedBrand(e.target.value)
          }}
          >
            <option>Marca</option>
        
        {
       GetBrandArry().map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAproduct"        
          onChange={(e) => {
            setProductID(e.target.value)
          }}
          >
            <option>Producto</option>
       {GetProductArray().map(({_id,Name,Model})=>(             
                  <option key={_id} value={_id}> {Name}{Model}</option>
                ))}
          </Form.Select>
          </Container>
          <Container className=' pt-2 d-flex justify-content-center'>
            <Form.Group>
            <Form.Label>Selecciona Una Categoría</Form.Label>
          <Form.Select 
          
          id="selectCategory"        
          
          onChange={(e) => {
            setPromoCategory(e.target.value)
          }}
          >
            <option></option>
       {PromoCategories.map((c)=>(             
                  <option key={c._id} value={c._id}> {c}</option>
                ))}
          </Form.Select>
          </Form.Group>
          </Container>  
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative">
            <Form.Label>Agrega tu banner</Form.Label>
            <Form.Control
              type="file"
              required
              name="Agrega tu banner"
              onChange={TransformTo64}   
            />   
            {picture==="" || picture===null ? "":  <img src={picture} width={100} height={100}></img>}
           
          </Form.Group>
          </Container>
          <Container className='pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative mb-1" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Agrega una descripción corta</Form.Label>
        <Form.Control as="textarea" rows={1} onChange={(e)=>setDescripcion(e.target.value)}/>
      </Form.Group>
          </Container>
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={()=>HandleCreatePromo()}>Salvar Cambios</Button>
            </Card.Footer>
          </Card>
         </Container>
      </Row>
      <Row>

      </Row>
  <PosterUpdate />      
      <Row>
        <Container fluid>
          <Button variant='secondary' style={{width:'12rem',marginTop:'8rem'}} onClick={()=>Navigate('/home',{replace:true})} >Salir</Button>
        </Container>
      </Row> 
    </div>
  )
}

export default Marketing