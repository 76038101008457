import React from "react"
import "../../Styles/style.css" 
import { Card} from "react-bootstrap" 
import LogoSolar from "../images/SolarMax.png"
import '../LogFrm/style.css'



const CardReg=(props)=>{
    return(
      <div >
         <div className="d-flex justify-content-center">
              <Card className="LoginCard mt-5" style={{ width: '18rem',   }}>
                <div className="Cardlogo">
                  <img src={LogoSolar} alt="logomain"/>
                </div>
                
                <Card.Body>
                  {props.children}
                  <Card.Link href="/Home" className="liga">No estoy listo, Volver a página principal</Card.Link>
                </Card.Body>
              </Card>
        </div>
      </div>
  
    
    )
   
}

export default CardReg