import React from 'react'
import Background from "../../components/images/MainPage/SwiperIMG/CARRUSEL5.png"

function Bkgnd1(props){
    return (
        <div style={{
                    backgroundImage: `url(${Background})`,
                    backgroundRepeat: 'no-repeat',        
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: 'auto',
                    minHeight:'100vh'
                    }}>
            {props.children}
        </div>
    );
}

export default Bkgnd1
