import React from 'react'
import { Card } from 'react-bootstrap'

const CardContainer = (props) => {
  return (
    <div className='d-flex justify-content-center'>
        <Card style={{position:'relative',backgroundColor:'#f7f7f740',width:'46rem'}}>
            <Card.Header style={{fontWeight:'bolder',
            fontSize:'16pt'
        }}  >Ven a Visitarnos:
                <br></br>
                
            <Card.Link 
                style={{color:'#ffffff', 
                fontSize:'14pt',
                textShadow:'1px'
                }}
                href='https://www.google.com/maps/place/solarmax+energia/@28.5908525,-106.1104462,18z/data=!4m5!3m4!1s0x0:0x248df917d2a78d09!8m2!3d28.5914392!4d-106.110135'>
                    Av Silvestre Terrazas No. 11800, Esperanza, 31104 Chihuahua, Chih., Mexico
            </Card.Link>
            </Card.Header>
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    </div>
  )
}

export default CardContainer