
import './App.css';
import {Route, Routes} from "react-router-dom";
import Admin from './pages/Admin';
import Layout from './components/Layout';
import Catalog from './pages/products';
import RequireAuth from './Authentication/RequireAuth'
import Login from './pages/login';
import Supply from './pages/supply';
import Inicio from './pages/Inicio';
import Downloads from './pages/downloads';
import Registro from './pages/Registro';
import GIT from './pages/GetInTouch';
import Soporte from './pages/Soporte';
import Soluciones from './pages/solutions';
import Sales from './pages/Sales';
import HelpDesk from './pages/HelpDesk';
import TicketChat from './pages/TicketChat';
import Pswrst from './pages/PswRST';
import Promotions from './pages/Promotions';
import Unauthorized from './pages/Unauthorized';
import MercadoTecnia from './pages/Marketing';
import EditCatalog from './pages/CatalogEdit';
import PersistentLogin from './components/PersistentLogin';
import MainUpdate from './pages/Marketing/UpdateLayout'






function App() {
  return (
    <div className="App">
      <Routes>
      <Route element={<PersistentLogin/>}>
        <Route path="/" element={<Layout/>}>
          
          <Route path="/" element={<Inicio/>}/>
          </Route>
        </Route>
        {/* public routes */}
        <Route>
          {/* <Route path="Home" element={<Inicio/>}/> */}
          <Route path="login" element={<Login/>}/>
          <Route path='rstpswd' element={<Pswrst/>}/>
          <Route path='registro' element={<Registro/>}/>
          <Route path="Downloads" element={<Downloads/>}/>
          <Route path='chatticket' element={<TicketChat/>}/>
          <Route path='unauthorized' element={<Unauthorized/>}/>
          {/* <Route path='marketing' element={<MercadoTecnia/>}/>          */}
        </Route>
        {/* Semi- Private Routes */} 
        {/* semiprivate routes are wrapped by the persisting login since we require auth to manipulate certain functions  */}
        <Route element={<PersistentLogin/>}>
        <Route path="Home" element={<Inicio/>}/>
        <Route path="products" element={<Catalog/>}/>
        <Route path="soluciones" element={<Soluciones/>}/>
        <Route path="contacto" element={<GIT/>}/>
        <Route path="soporte" element={<Soporte/>}/>
        <Route path="promociones" element={<Promotions/>}/>
        </Route>
       
        {/* Total- Private Routes  RequireAuth is a must*/}
        <Route element={<PersistentLogin/>}>
        <Route element={<RequireAuth allowedRoles={[1950]}/>}>
          <Route path="supply" element={<Supply/>}/>
          </Route>
        <Route element={<RequireAuth allowedRoles={[1998]}/>}>
          <Route path='ventas' element={<Sales/>}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={[1978]}/>}>
          <Route path='helpdesk' element={<HelpDesk/>}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={[2023]}/>}>
          <Route path='CatEdit' element={<EditCatalog/>}/>
        <Route path='marketing' element={<MercadoTecnia/>}/> 
        <Route path='apperance' element={<MainUpdate/>}/>
        </Route>
        {/* <Route element={<RequireAuth allowedRoles={[5150]}/>}>            
        <Route path="quote" element={<Quotes/>}/>
          </Route> */}
        <Route element={<RequireAuth allowedRoles={[5150]}/>}>             
          <Route path="Admin" element={<Admin/>}/> 
        </Route>
        </Route>

        
      </Routes>
   
   
      
    </div>
  );
}

export default App;
