import React from 'react'
import './slogan.css'
import { Container, Row } from 'react-bootstrap'
import logo from '../images/MainPage/SolarMaxBW.png'

const Slogan = () => {
  return (
    <div  className='legend'>
        <Container className='wrapping'>
        
        <div className='legendS'>
        <h1>ponemos el sol en tus manos</h1>
        </div>
        </Container>
        </div>
  )
}

export default Slogan