import React, {useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row,Container, Card, Button,Form} from 'react-bootstrap'
import ApiCall from '../../AxiosCalls'

const PosterUpdate = () => {
    const Navigate=useNavigate();
    const [image,setImage]=useState() // used for the muter strategy 
    const [currentImg,SetcurrentImg]=useState([])
    const [icon,setIcon]=useState(null)
    const [imgName,setImgName]=useState()
    const [refresh,setRefresh]=useState(false)
 
    useEffect(()=>{    
      AllImages();
    },[refresh])  

    
    const AllImages=async()=>{    
    const allImg= await ApiCall.GetPosters()
      .then(res=> res.data )
      .catch(err=>console.log(err))
      SetcurrentImg(allImg)
    }



//// constructing the request using FormData 
const handleSubmit=async()=>{
  const formData = new FormData() // using the FormData property to provide all params 
  formData.append("file",image)   // using "file" as the name on the FormData so it can be parsed to the backend as req.file
  formData.append("name",imgName) // We append the property of name to the FormData so it can be part of the req.body
    await ApiCall.PosterUpload(formData)
  .then(res=> res.status===200 && alert("Poster Agregado Exitosamente!"))
   .catch(err=>console.log(err))
   setIcon(null);
   setRefresh(!refresh)
}

//function to transform the file on 64 format 
const TransformTo64=(e)=>{
    let reader= new FileReader();
    reader.readAsDataURL(e.target.files[0])
    reader.onload = ()=>{
        // console.log(reader.result);  // here it is our sting base64
        setIcon(reader.result) //this saves it on the state function 
    }
    reader.onerror= e=>{console.log(e)}
}

// function to eliminate one carousel image from DB
const eliminate=async(ID,URL)=>{
const SlicedURL= thePath=>thePath.substring(thePath.lastIndexOf('/')+1)   // method to truncate last "/" on the URL  
const ImgName=SlicedURL(URL)
const payload={
    id:ID,
    image:ImgName
}
  await ApiCall.RemovePoster(payload)
  .then(res=>res.status===204 && alert("Poster Eliminado Exitosamente!"))
  .catch(err=>{
    console.log(err);
    alert("hubo un error en la base de datos intentalo nuevamente")
  })
  setRefresh(!refresh)
}

  return (
    <div>
         
      <Row>
        <Container style={{backgroundColor:'transparent'}} className=" mt-2 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00005250',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Actualizacion de Poster</Card.Title>
            <Card.Body>  
          <Form style={{backgroundColor:'transparent'}} >
          <Container className=' pt-2 d-flex justify-content-center'>
              <Form.Group className="position-relative" controlId='file.input'>
                <Form.Label>Agrega imagen al carrousel</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="Picture"
                  
                  onChange={e=>{setImage(e.target.files[0]); TransformTo64(e)}}   
                />   
                {image==="" || image===null ? "":  <img src={icon} width={100} height={100}></img>}
              </Form.Group>
          </Container>
          <Container className='pt-2 d-flex justify-content-center'>
                <Form.Group className="position-relative mb-1" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Agrega una descripción corta</Form.Label>
              <Form.Control as="textarea" rows={1} value={imgName} onChange={(e)=>setImgName(e.target.value)}/>
              </Form.Group>
          </Container>
          <Row>
            <Container fluid className='mt-3'>
                <Button variant='light' type='submit' style={{background:'#FAB771'}} onClick={(e)=>{
                  e.preventDefault();
                  handleSubmit(e);
                } }>Salvar Cambios</Button>
            </Container>
          </Row>          
          </Form>
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Container fluid>
            <Row className='d-flex justify-content-center'>
              <h1 style={{fontSize:'18pt'}}>Remover Imagen Existente</h1>
              { currentImg=== null ? "" : currentImg.map(({_id,picture,name})=>{
                //decoder to convert binary images stored in mongo to base64
                // const DecodedImg= btoa(String.fromCharCode(...new Uint8Array((picture.data)))) 
                return(
                  <Card key={_id} className='m-2' style={{ width: '10rem',backgroundColor:'#00000050',color:'#FFFFFF' }}>
                <Card.Img variant="top" src={picture} />
                <Card.Body>            
                  <Card.Text>
                  {name}
                   </Card.Text>
                  <Button variant="danger" onClick={()=>eliminate(_id,picture)} >Remove</Button>
                
                </Card.Body>
             </Card>
                )
              })}
            </Row>
            </Container>
            </Card.Footer>
          </Card> 
         </Container>
      </Row>
   
    </div>
  )
}

export default PosterUpdate