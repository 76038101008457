import React, {useEffect, useState} from 'react'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'
import Brand from '../../components/Brands'
import Support from '../support'
import useAuth from '../../components/Hooks/useAuth'
import {Row,Container} from 'react-bootstrap'
import PromoCard from '../../components/ProductCard/PromoCard'

const Promotions= (props) => {
const{allPromos,auth}=useAuth()
const [promoCategories]=useState(["Promociones activas", "Lo más vendido", "Lo más buscado"])

useEffect (()=>{      
   },[])

   const FilterPromos =(category)=>{
        const Promos=allPromos.filter(promo=>promo.PromoCategory === category)
        return Promos
      }

  return (
    <div>
    <Header/>
    <NavBar/>
  
    <Row>
    <Container>
        <section style={{color:'#2c2c2c',colorBackground:'#000000FF',fontSize:'18pt'}}>Promociones Activas<br></br> {props.children}</section>
    </Container>
    </Row>
    <Row className='ProdGrid'>
        {FilterPromos(promoCategories[0]).map(({_id,ProductREFid,PromoTitle,PromoImage,PromoDesc})=>{
                    return(
                    <PromoCard 
                    Key={_id}
                    RefId={ProductREFid}
                    title={PromoTitle}
                    description={PromoDesc}
                    image={PromoImage}    
                         />
                    )
                })}
    </Row>
{/* another Row */}
<Row>
    <Container>
        <section style={{color:'#2c2c2c',colorBackground:'#000000FF',fontSize:'18pt'}}>Lo Más Vendido<br></br> {props.children}</section>
    </Container>
    </Row>
    <Row className='ProdGrid'>
        {FilterPromos(promoCategories[1]).map(({_id,ProductREFid,PromoTitle,PromoImage,PromoDesc})=>{
                    return(
                    <PromoCard 
                        Key={_id}
                        RefId={ProductREFid}
                        title={PromoTitle}
                        description={PromoDesc}
                        image={PromoImage}
                       
                         />
                    )
                })}
    </Row>
    {/* another Row */}
<Row>
    <Container>
        <section style={{color:'#2c2c2c',colorBackground:'#000000FF',fontSize:'18pt'}}>Lo Más Buscado<br></br> {props.children}</section>
    </Container>
    </Row>
    <Row className='ProdGrid'>
        {FilterPromos(promoCategories[2]).map(({_id,ProductREFid,PromoTitle,PromoImage,PromoDesc})=>{
                    return(
                    <PromoCard 
                    Key={_id}
                    RefId={ProductREFid}
                    title={PromoTitle}
                    description={PromoDesc}
                    image={PromoImage}
                   
                       
                         />
                    )
                })}
    </Row>
<Brand/>
<Support/>
</div>
  )
}

export default Promotions