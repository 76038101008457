import React, { useState,useEffect } from 'react'
import { Container,Row,Card,Button } from 'react-bootstrap'
import AnswerTicket from '../Modals/AnsTicket'
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'

const Help = () => {
    const [closedTickets,setClosedTickets]=useState([])
    const [everyTicket,setEveryTicket]=useState([])
    const {showTickets,setShowTickets,updateOpenTicket}=useAuth()

    useEffect(()=>{
        callTickets();

    },[updateOpenTicket])
    const callTickets=async()=>{
        let tickets= await ApiCall.AllTickets()
        .then(res=>res.data)
        .catch(e=>console.log());
        setEveryTicket(tickets.filter(t=>t.CloseStatus === false));
        setClosedTickets(tickets.filter(q=>q.CloseStatus === true))
      }

  return (
    <div>
        <Container fluid style={{color:'#FAB771',backgroundColor:'#00000050'}}>
            <h1>Bienvenido al Administrador de Tickets!
            </h1>
        </Container>
        <Row>
        <Container style={{backgroundColor:'transparent'}} className="d-flex justify-content-center">
        <Card style={{height:'12rem',width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='mt-2 d-block  justify-text-center'>
            <Card.Title>Tickets de soporte</Card.Title>
            <Card.Body> { everyTicket ? ( <Container className=' pt-2 d-flex justify-content-center'>
          Tickets Abiertos : {everyTicket.length}
              <br/>
          Tickets Cerrados: {closedTickets.length}
            </Container>    
                ):(<></>) }</Card.Body>
                  <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={(e)=>setShowTickets(!showTickets)}>Ver Tickets Abiertos</Button>
            </Card.Footer>
          </Card>
          {showTickets && <AnswerTicket alltickets={everyTicket} doneTickets={closedTickets}/>}
                 
        </Container>
        </Row>
    </div>
    
  )
}

export default Help