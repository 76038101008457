import React from 'react'
import Fondo from '../../components/bkgImg'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'
import Support from '../support'
import Solucion from '../../components/Solutions'
import useAuth from '../../components/Hooks/useAuth'

const Soluciones = (props) => {
    const {solution}=useAuth();
  return (
   <Fondo>
    <Header/>
    <NavBar/>
   <Solucion>{solution}</Solucion>

    <footer>
        <Support></Support>
    </footer>

   </Fondo>
  )
}

export default Soluciones