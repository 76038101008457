import React from 'react'
import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from '../components/Hooks/useAuth';


const RequireAuth = ({allowedRoles}) => {
    const{auth}=useAuth();
    const location=useLocation();
      //  console.log(auth);
 
  return (

    //  auth.roles?.find(role=> allowedRoles?.includes(role)) && auth?.isAuthenticated
     auth.roles?.find(role=> allowedRoles?.includes(role)) 
      ? <Outlet/>
      : !auth?.isAuthenticated
        ? <Navigate to="/Login" state={{from:location}} replace />
        : <Navigate to="/unauthorized" state={{from:location}} replace/> 
  )
}

export default RequireAuth