import axios from "axios"




export const AuthService={

    //here we declare to use our hook of axios private that will refresh the token 
       
        login: async (Payload)=>{
            
             const answer = await axios.post('/user/login',Payload,{withCredentials: true})    
            return (answer.data)
        },
        register:(payload)=>{
            return axios.post("/user/new",payload)
        },
        logout:async () =>{
            return axios.get("/logout")          
        }

}