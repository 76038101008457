import React from 'react'
import { Container,Form,Button } from 'react-bootstrap'
import '../LogFrm/style.css'
import useAuth from '../Hooks/useAuth'
import ApiCall from '../../AxiosCalls'

const RegFrm = () => {
    const {userState,userDispatch}=useAuth();

    const HandleOnChange=(e)=>{
        e.preventDefault();
        userDispatch({
            type:"ADD_USER",
            payload:{
                input:e.target.name,
                value:e.target.value 
            }
        })
    }
    const HandleSubmit=async()=>{
        try{
            await ApiCall.Register(userState)
            .then(res=>{

                console.log(res);
              
                alert("Usuario Registrado Satisfactoriamente")
              
            
               
            })
        }catch (err){
            console.log(err);
        };
    }
  return (
    <Container className='masthead color-overlay d-flex justify-content-center align-items-center'>
    <Form className="rounded p-4 p-sm-3">

    <Form.Group className="mb-3" controlId="floatingTextarea">
        <Form.Label>Elige tu nombre de usuario</Form.Label>
        <Form.Control type="text" placeholder="Nombre de usuario" name="username" onChange={(e)=>{HandleOnChange(e)}} />
        <Form.Text className="text-muted">
        </Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control type="email" placeholder="Correo electronico"  name="email" onChange={(e)=>{HandleOnChange(e)}} />
        <Form.Text className="text-muted">
        Nunca compartiremos tus datos personales con nadie.
        </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="floatingTextarea">
        <Form.Label>Escribe tu nombre</Form.Label>
        <Form.Control type="text" placeholder="Primer nombre"  name="FirstName" onChange={(e)=>{HandleOnChange(e)}} />
        <Form.Text className="text-muted">
        </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="floatingTextarea">
        <Form.Label>Escribe tu apellido</Form.Label>
        <Form.Control type="text" placeholder="Apellido" name="LastName" onChange={(e)=>{HandleOnChange(e)}} />
        <Form.Text className="text-muted">
        </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="floatingTextarea">
        <Form.Label>Nombre de tu Compañia:</Form.Label>
        <Form.Control type="text" placeholder="Ej: SolarMax Energía" name="company" onChange={(e)=>{HandleOnChange(e)}} />
        <Form.Text className="text-muted">
        </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control type="password" placeholder="Password" name="password" onChange={(e)=>{HandleOnChange(e)}}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Repite tu contraseña</Form.Label>
        <Form.Control type="password" placeholder="Password"  name="password2" onChange={(e)=>{HandleOnChange(e)}}/>
    </Form.Group>
    <Button style={{backgroundColor:'orange',border:'none',color:'#000000'}} type="submit" onClick={(e)=>{e.preventDefault();HandleSubmit();}}>
        Registrar
    </Button>
    <br></br>
    <Form.Text style={{color:'gray'}}>
        ¿Ya tienes una cuenta? Has clic en el enlace de abajo para iniciar sesión
    </Form.Text>
    <br></br>
    <a href='/login' role='button' style={{color:'orange'}}>Inicio de sesión</a>
    <br></br>
   
    </Form>
</Container>
  )
}

export default RegFrm