import React, {useEffect, useState} from 'react'
import { Button, Form,Row } from 'react-bootstrap'
import '../../pages/products/Style.css'
import useAuth from '../Hooks/useAuth'

const Filter = () => {

const {setMybrand,mybrand,
  products,productState:{byName},
  productDispatch}=useAuth();
const allCat= products.map(product=>product.Category)
const [current,setCurrent]=useState("")
const Things= [...new Set(allCat)];
const Items=Things.sort()
const [flag,setFlag]=useState(false);
const [all,setAll]=useState(false);
const [checked,setChecked]=useState(false);

useEffect(()=>{
  // console.log(allCat);
  console.log(mybrand);
},[mybrand])

 const DisplayAll =()=>{
    Items.map(category=>{   
       return (productDispatch({
      type:"Add_All",
      payload:{Name:category} })
     )});;
      setAll(true)
    return;
 }


 const HandleChange=(category)=>{
  
  (current === category? setCurrent(""):setCurrent(category))
  setAll(false)
  // setCurrent(category)
  //method to recognize if selected category is already included 
  const NewArry=byName.map(c=>c.Name)
  const Arry2=[...new Set(NewArry)];
  const tester=Arry2.includes(category)
 if (!tester){
  setFlag(true)
  productDispatch(
   
    { type:"UNIQUE_CATEGORY",
      payload:{Name:category}
    })

  }
  else {
    setFlag(false);
  productDispatch(
    { type:"CLEAR_FROM_SORT",
      payload:category
    }
  )}
  setMybrand(null);
}

const clearFilter=()=>{
 //reseting the window to clear all and making sure byName is empty 
 setCurrent("")
 setAll(false)

  productDispatch({
    type:"CLEAR_FILTERS"
  })
}

  return (
    <div>
      <Row>
        <a href='#' style={{textDecoration:'none', color:'orange', fontSize:'11pt',
        textTransform:'capitalize' }}  onClick={DisplayAll}    >selecciona todo</a>
        </Row>
        <Row>
        {Items.map((category)=>{       
          return(
            all ? (
            <Form.Check inline name='group1' id={category.id} className='checkboxes' key={category.id} 
            label={<a href='#' onClick={()=>HandleChange(category)} style={{textDecoration:'none',color:'#2d2d2d'}} >{category}</a>}
            onChange={()=>{HandleChange(category)}}      
            checked={true}
            type='checkbox'
            />):(
              <Form.Check inline name='group1' id={category.id} className='checkboxes' key={category.id} 
              label={<a href='#' onClick={()=>HandleChange(category)} style={{textDecoration:'none',color:'#2d2d2d'}} >{category}</a>}
            onChange={()=>{setAll(false);HandleChange(category)}} 
             checked={current=== category ? true :false} 
            type='checkbox'
           
            />
             )
          )
          }
             )}
        </Row>
        <Row>
          <div className='btn-sm'>
          <Button onClick={()=>{setFlag(false);clearFilter()}} className="mt-2 btn-sm" style={{backgroundColor:'#004aad', textTransform:'uppercase'}}>Remover Filtros</Button>
          </div>
        </Row>
    </div>
  )
}

export default Filter