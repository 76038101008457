import React, {useState,useEffect,useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row,Container, Card, Button,Form} from 'react-bootstrap'
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'


const CatlogEdit = () => {
    const Navigate=useNavigate();
    const [imgURL,setImgURL]=useState("")
    const [icon,setIcon]=useState(null)
    const [picture,setPicture]=useState("")
    const [category,setCategory]=useState("Linea de Producto")
    const [productID,setProductID]=useState(""); 
    const [removeID,setRemoveID]=useState("");  
    const [selectedBrand,setSelectedBrand]=useState("Marca")
    const [descripcion,setDescripcion]=useState("")
    const [productBrand,setProductBrand]=useState("");
    const [productName,setProductName]=useState("Producto");
    const [productModel,setProductModel]=useState("Modelo");
    const [productDataSheet,setProductDataSheet]=useState("");
    const [productPicture,setProductPicture]=useState("")
    const [productLine,setProductLine]=useState([])
    const [productLargeDescription,setProductLargeDescription]=useState("");
    const [productShortDescription,setProductShortDescription]=useState("");
    const {products,reload,setReload}=useAuth()
  

    useEffect(()=>{ 

      InitializeProduct();  
    },[reload,products])  

    const InitializeProduct=()=>{
      // declaring all filters
  const ItemsCat=products.map(product=>product.Category)
  const ProductCategory=[...new Set(ItemsCat)]
  setProductLine(ProductCategory)
    }



   const GetBrandArry=()=>{
    //filter only by selected category 
    const BrandArry = products.filter(x=>x.Category === category)
     let TempBrand= BrandArry.map(product=>product.Brand)
   let NewBrands=[...new Set(TempBrand)]
    return NewBrands
}

//function to filter and products once the Category and Brands are selected 
const GetProductArray=()=>{
    let ProductFilter= products.filter(b=>b.Category === category && b.Brand === selectedBrand)
    return ProductFilter        
}



// Function to transform an image file to Base64
const transformImageToBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result);
  };
  reader.onerror = (error) => {
    console.error(error);
  };
};

  // Function to handle image input changes and update state with Base64 data
  const handleImageInputChange = (event, setStateFunction) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      transformImageToBase64(imageFile, (base64String) => {
        setStateFunction(base64String);
      });
    }
  };


  // Function to transform a file to Base64
  const transformFileToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
    };
    reader.onerror = (error) => {
      console.error(error);
    };
  };

  // Function to handle file input changes and update state with Base64 data
  const handleFileInputChange = (event, setStateFunction) => {
    const file = event.target.files[0];
    if (file) {
      transformFileToBase64(file, (base64String) => {
        setStateFunction(base64String);
      });
    }
  };

//function to add/update/create a promotion 
const HandleUpdateProduct=async()=>{
    //sending the file to the backend using formdata
    const formData= new FormData();
    formData.append("file",picture)
    formData.append("name",descripcion)
    formData.append("id",productID)
    formData.append("datasheet",productDataSheet)
    await ApiCall.UpdateProduct(formData)
    .then(res=> res.status===200 && alert("Producto Actualizado Exitosamente!"))
    .catch(e=>{
        console.log(e);
        alert("No fue posible actualizar el producto, verifica que los datos estén correctos")
    })
    setReload(!reload) // reloads the main request for products to update the site 
    

}

//function to remove existing product  
const HandleRemoveProduct=async()=>{
  console.log(imgURL);
  console.log(removeID);
  const SlicedURL= thePath=>thePath.substring(thePath.lastIndexOf('/')+1)   // method to truncate last "/" on the URL
  const ImgName=SlicedURL(imgURL)
  const Payload={
    id:removeID,
    image:ImgName
  }
  console.log(Payload);
  
  await ApiCall.RemoveProduct(Payload)
  .then(res=> res.status===200 && alert("Producto Eliminado!"))
  .catch(e=>{
      console.log(e);
      alert("No fue posible Eliminar el producto, verifica que los datos estén correctos")
      setCategory("");
      setSelectedBrand("");
      setRemoveID("")
  })
  setReload(!reload) // reloads the main request for products to update the site 

}

//Function to create a product
const HandleCreateProduct=async()=>{
  console.log(picture);
  const Payload={
    Category:category,
    Brand:productBrand,
    Name:productName,
    Model:productModel,
    DescripcionCorta:productShortDescription,
    DescripcionLarga:productLargeDescription,
  }
   //sending the file to the backend using formdata
   const formData= new FormData();
   formData.append("files",JSON.stringify(Payload))
   formData.append("file",picture)
   formData.append("datasheet",productDataSheet)
  await ApiCall.AddProduct(formData)
  .then(res=> res.status===200 && alert("Producto guardado exitosamente"))
  .catch(e=>{
    console.log(e);
    alert("No fue posible crear el producto, verifica que los datos sean correctos")
  })
  setReload(!reload) // reloads the main request for products to update the site 
}

  //function to transform the file on 64 format 
  const TransformTo64=(e)=>{
    let reader= new FileReader();
    reader.readAsDataURL(e.target.files[0])
    reader.onload = ()=>{
        setIcon(reader.result) //this saves it on the state function 
    }
    reader.onerror= e=>{console.log(e)}
  }

  return (
    <div> 
         <Row>
      </Row>
        <Container fluid className='mt-2' style={{fontSize:'16pt',fontWeight:'bold'}}> Edición de catálogo</Container>
      <Row>
        <Container style={{backgroundColor:'transparent'}} className=" mt-5 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Editar un producto existente</Card.Title>
            <Card.Body>       
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectProductLine"        
         onChange={(e)=>{setCategory(e.target.value);
         }}
          >
            <option>línea de producto</option>
       {productLine.map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAbrand"        
          onChange={(e) => {
            setSelectedBrand(e.target.value)
          }}
          >
            <option>Marca</option>       
        {
       GetBrandArry().map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAproduct"        
          onChange={(e) => {
            setProductID(e.target.value)
          }}
          >
            <option>Producto</option>
       {GetProductArray().map(({_id,Name,Model})=>(             
                  <option key={_id} value={_id}> {Name}{Model}</option>
                ))}
          </Form.Select>
          </Container>
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative">
            <Form.Label>Actualiza o agrega foto del producto</Form.Label>
            <Form.Control
              type="file"
              required
              name="Agrega tu banner"
             
              onChange={e=>{setPicture(e.target.files[0]); TransformTo64(e)}} 
            />   
            {picture==="" || picture===null ? "":  <img src={icon} width={100} height={100}></img>}
        
          </Form.Group>
          </Container>
         
          <Form.Group className='position-relative'>
          <Form.Label>Actualiza la hoja tecnica del producto</Form.Label>
          <Form.Control
              type="file"
              required
              name="GetDatasheet"
              onChange={e=>{setProductDataSheet(e.target.files[0])}}    
            />
          </Form.Group>
          
          <Container className='pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative mb-1">
        <Form.Label>Agrega una descripción detallada</Form.Label>
        <Form.Control as="textarea" rows={2} onChange={(e)=>setDescripcion(e.target.value)}/>
      </Form.Group>
          </Container>
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={()=>HandleUpdateProduct()}>Salvar Cambios</Button>
            </Card.Footer>
          </Card>
         </Container>
{/* add product window */}
         <Container style={{backgroundColor:'transparent'}} className=" mt-5 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Añadir un producto nuevo</Card.Title>
            <Card.Body>
   
          
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Control type="input" 
                        placeholder="Linea de producto"
                        onChange={(e)=>setCategory(e.target.value)}
          />

          <Form.Control type="input" 
                        placeholder="Marca"
                        onChange={(e)=>setProductBrand(e.target.value)}
          />
          <Form.Control type="input" 
                        placeholder="Producto"
                        onChange={(e)=>setProductName(e.target.value)}
          />
          <br></br>
          </Container>
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Control type="input" 
                        placeholder="Modelo"
                        onChange={(e)=>setProductModel(e.target.value)}
                        style={{ width: '200px' }}
          />
          </Container>
         
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative">
            <Form.Label>Agrega foto del producto</Form.Label>
            <Form.Control
              type="file"
              required
              name="Agrega tu banner"
           
              onChange={e=>{setPicture(e.target.files[0]); TransformTo64(e)}} 
            />   
            {picture==="" || picture===null ? "":  <img src={icon} width={100} height={100}></img>}
          </Form.Group>
          </Container>
          <Form.Label>Agrega la hoja tecnica del producto</Form.Label>
          <Form.Control
              type="file"
              required
              name="Agrega tu banner"
              // onChange={(e) => handleFileInputChange(e, setProductDataSheet)} 
              onChange={e=>{setProductDataSheet(e.target.files[0])}}    

            />
          <Container className='pt-2 d-flex justify-content-center'>
          <Form.Group className="position-relative mb-1" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Agrega una descripción corta</Form.Label>
        <Form.Control as="textarea" rows={1} onChange={(e)=>setProductShortDescription(e.target.value)}/>
        <Form.Label>Agrega una descripción Larga</Form.Label>
        <Form.Control as="textarea" rows={3} onChange={(e)=>setProductLargeDescription(e.target.value)}/>
      </Form.Group>
          </Container>
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={()=>HandleCreateProduct()}>Salvar Cambios</Button>
            </Card.Footer>
          </Card>
         </Container>
      </Row>
      <Row>
      </Row>
        <Container style={{backgroundColor:'transparent'}} className=" mt-5 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Eliminar un producto existente</Card.Title>
            <Card.Body> 
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectProductLine"        
         onChange={(e)=>{setCategory(e.target.value);
         }}
          >
            <option>{category}</option>
       {productLine.map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAbrand"        
          onChange={(e) => {
            setSelectedBrand(e.target.value)
          }}
          >
            <option>{selectedBrand}</option>
        {
       GetBrandArry().map((c)=>(             
                  <option key={c._id} value={c}> {c}</option>
                ))}
          </Form.Select>
          <Form.Select 
          id="selectAproduct"        
          onChange={(e) => {
            const passed=JSON.parse(e.target.value)
            console.log(passed);
            setRemoveID(passed.id)
            setImgURL(passed.cloudUrl)
          }}
          >
            <option>{productModel}</option>
      {GetProductArray().map(({_id,Name,Model,Picture})=>{
        
        return(
          <option key={_id} value={JSON.stringify({id:_id,cloudUrl:Picture})} > {Name}{Model}</option>
        )
      })}


          </Form.Select>
          </Container>
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='danger'  onClick={()=>HandleRemoveProduct()}> <i className="fa-duotone fa-trash-can"></i> Eliminar</Button>
            </Card.Footer>
          </Card>
         </Container>
      <Row>
        <Container fluid>
          <Button variant='secondary' style={{width:'12rem',marginTop:'8rem'}} onClick={()=>Navigate('/home',{replace:true})} >Salir</Button>
        </Container>
      </Row> 
    </div>
  )
}

export default CatlogEdit