import React, {useState,useRef} from 'react'
import { Container,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Image from 'react-bootstrap/Image';

const PromoPop = (props) => {
    const [show,setShow]=useState(true)
    const ref = useRef(null);

  return (
 <div ref={ref} className="custom-modal">
  <Overlay
    target={props.target}
    show={show}
    title={props.title}
    placement="bottom"
    container={ref}
    containerPadding={20}
  >
    <Popover id="popover-contained" className="custom-popover">
      <Popover.Header as="h3">{props.title}</Popover.Header>
      <Popover.Body>
        <Container>
          <Col xs={12} md={6}>
            <Image src={props.img} className="custom-image" />
          </Col>
        </Container>
        <Container className="d-flex justify-content-center mt-2">
          <Button variant="dark" onClick={() => setShow(!show)}>
            Cerrar Ventana
          </Button>
        </Container>
      </Popover.Body>
    </Popover>
  </Overlay>
</div>
    
  )
}

export default PromoPop