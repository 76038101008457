import React,{useState,useRef} from 'react'
import {Container,Button,Overlay,Popover ,ListGroup,Nav} from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'

const ChangeSettings = (props) => {
    const [show,setShow]=useState(true)
    const {setProfilemodal,setSelectUpdate}=useAuth();
    const ref = useRef(null);
 
  return (
    <div ref={ref} style={{width:'10rem',backgroundColor:'pink'}}>
      <Overlay
        show={show}
        target={props.target} // i linked it to the line that sets the command on the previous component 
        placement="right"
        container={ref}
        containerPadding={20}    
      >
        <Popover id="popover-contained" style={{maxWidth:'15rem',backgroundColor:'#A1A1A1'}}>
          <Popover.Header as="h1" style={{color:'#2D2D2D'}}>Actualiza tu perfil</Popover.Header>
          <Popover.Body>
            <ListGroup variant='flush'>
                {/* <ListGroupItem style={{color:'#DFE4FD',fontSize:'12px', fontFamily:'sans-serif',textShadow:'#2D2D2D 1px 0 10px'}}>Actualiza tu perfil</ListGroupItem> */}
                <Nav defaultActiveKey="/home" className="flex-column" >
                 
                  <Nav.Link eventKey="link-1" style={{fontSize:'12px'}}
                  name='e-mail' 
                   onClick={(e)=>{e.preventDefault();
                    setProfilemodal(true) ;
                    setSelectUpdate(e.target.name)
                    setShow(false);
                  
            }}>Actualizar e-mail</Nav.Link>
          {/* <Nav.Link eventKey="link-3" style={{fontSize:'12px'}} 
            name='No de Whatsapp' 
            onClick={(e)=>{e.preventDefault();
             setProfilemodal(true) ;
             setSelectUpdate(e.target.name)
             setShow(false);
          }}>Cambiar Número de whatsapp</Nav.Link> */}
                  <Nav.Link eventKey="link-2" style={{fontSize:'12px'}}
                   name='password' 
                   onClick={(e)=>{e.preventDefault();
                    setProfilemodal(true) ;
                    setSelectUpdate(e.target.name)
                    setShow(false);  
        }}>Cambiar Password</Nav.Link>              
    </Nav>
            </ListGroup>
            <Container className='d-flex justify-content-center mt-2'>
            <Button variant='dark' style={{borderRadius:'8rem'}} onClick={()=>setShow(false)}>Salir</Button>
<br/>
{/* <Button variant='dark' onClick={()=>getUsers()}>Test Users</Button> */}
            </Container>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>  
  )
}

export default ChangeSettings