import React from 'react'
import { Card, Button } from 'react-bootstrap'
import './style_TktCard.css'

const TicketCard = (props) => {
  return (
    <div className='d-flex justify-content-center'>
        <Card style={{position:'relative',backgroundColor:'#f7f7f740',width:'50rem'}}>
            <Card.Header style={{fontWeight:'bolder',
            fontSize:'16pt'
        }}  >
          
          <Button className='clickCalendar mt-4'>
            <a target="_blank" style={{color:'#ffffff'}} rel="noopener noreferrer" href='https://calendar.app.google/SFCfjBztXFFefiCJA'>
            <i className="fa-duotone fa-calendar-clock fa-xl" size='2xl' style={{"--fa-primary-color": '#d9a778', "--fa-secondary-color": '#cd491d'}}></i><span>Agenda Una Cita</span></a></Button>
              <br></br>
              ó
              <br></br>
          Crea Un Ticket de ayuda:
                <br></br>
                
            
            </Card.Header>
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    </div>
  )
}

export default TicketCard