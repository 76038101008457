import React  from 'react'
import { Container,Row , Col, Form, Button } from 'react-bootstrap'
import './style.css'
import useAuth from '../../components/Hooks/useAuth'
import ApiCall from '../../AxiosCalls'

const Contact = () => {
    const{distributorState,distributorDispatch}=useAuth();

const HandleOnChange=(e)=>{
    e.preventDefault();
    distributorDispatch({
        type:'ADD_Distributor',
        payload:{
            input:e.target.name,
            value:e.target.value
        }
    })
}


const HandleSubmit=async()=>{
    console.log(distributorState);
try{
    await ApiCall.AddDistributor(distributorState)
    .then(res=>{

        console.log(res);
      
        alert("Gracias por tu interes, nuestro equipo de ventas se pondra en contacto a la brevedad")
    })
}catch (err){console.log(err)
    alert("No fue posible enviar tu información por favor comunicate por teléfono")
}
}



  return (
    <Container fluid className='Conveyor mt-4'>
 
    <Container className='d-flex justify-content-center w-100 h-100 align-items-center '>
    <Row className='d-flex  ContactWrap2'>
    
       <Col  md={12} lg={6} className="contact-left text-center" >
        <h1>¿QUIERES SER DISTRIBUIDOR?</h1>
        <h4>Deja tus datos y te contactaremos a la brevedad</h4>
        
        
        <Row className='justify-content-center'>
            <h5>whatsapp BUSINESS</h5>

        </Row>
        <Row className='justify-content-center'>
            <h5>+52 1 (614) 247 1348</h5>

        </Row>
        <Row className='justify-content-center'>
            <h5>Ventas1@solarmaxenergia.com</h5>
        </Row>
        </Col>

        {/* <Col lg={6}> */}
       <Col xs={12} md={12} lg={6} className="contact-right text-center  " >  

      
           
            <Form className='contact-form p-4 p-sm-3'>
         
               
                <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label aria-required>Nombre de la empresa:</Form.Label>
                <br/>
                    <Form.Control type="name" 
                    placeholder="Tu empresa " 
                    name='Company' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                
                    </Form.Group>
                    

                 <Form.Group className='mb-3'>
                <Form.Label aria-required>Nombre del contacto:</Form.Label>
                <br/>
                    <Form.Control type="name" 
                    placeholder="Tu nombre " 
                    name='ContactName' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                   </Form.Group>
                <Form.Group className='mb-3'>
                <Form.Label aria-required>WhatsApp:</Form.Label>
                <br/>
                    <Form.Control type="input" 
                        placeholder="+52 (614)-____-____" 
                        name='WhatsAppNum' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                      </Form.Group>
                   
                <Form.Group className='mb-3'>
                <Form.Label aria-required>Correo   Electrónico:</Form.Label>
                <br/>
                    <Form.Control  
                    type="email" 
                    placeholder="Tu email" 
                    name='Email' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                    
                    <Form.Label >Ciudad:</Form.Label>
                    <br/>
                    <Form.Control type="name" 
                    placeholder="Cdmx" 
                    name='City' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                </Form.Group>
              
               
          
                    <Button variant="secondary" size="lg" 
                    type="submit" className='form-btn'
                    onClick={(e)=>{e.preventDefault();HandleSubmit();}}
                    >
                        Enviar
                    </Button> 
            

             </Form>
       </Col>

       {/* </Col> */}
       </Row>
    </Container>
    {/* </div> */}
    </Container>

  )
}

export default Contact