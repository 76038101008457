import React, {useEffect, useState} from 'react';
import { Button, Container, Modal, Form, Table } from 'react-bootstrap';
import useAuth from '../Hooks/useAuth';

import "./styleModal.css"

function MyVerticallyCenteredModal(props) { 
  const[mySwitch,setMySwitch]=useState(false)
  const[tickets,setTickets]=useState(props.alltickets);//we start with all the open tickes as default before changing state 
 
  useEffect(()=>{
  },[tickets])

const manageSwitch=()=>{

 mySwitch?(setTickets(props.alltickets)):(setTickets(props.alldone))
  setMySwitch(!mySwitch)
 }

   return (      
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header  closeButton>
          <Form className='d-flex justify-content-end' style={{background:'none'}}>
      <Form.Check 
      style={{backgroundColor:'transparent',color:'#FAB771',height:'0.5rem'}}
        type="switch"
        id="custom-switch"
        label={mySwitch ? ("Abiertos"):("Cerrados")}
        onChange={()=>manageSwitch()} 
        reverse
      />
       </Form>
         
          <Modal.Title id="contained-modal-title-vcenter" style={{color:'#FAB771',display:'flex',justifyContent:'center',}}>
     
     <Container className='d-flex justify-content-center'>
     {mySwitch? (<>Tickets Cerrados: {props.alldone.length}</>):(<> Tickets Abiertos: {props.alltickets.length}</>)}
     </Container>
 </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table responsive className="table table-dark table-hover">
          <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cliente</th>
          <th scope="col">Asunto</th>
          <th scope="col">Fecha (DD-MM-YYYY)</th>
        </tr>
      </thead>
      <tbody>
         
          {
         tickets.map((ticket)=>{
          let newdate= new Date(ticket.Start)
          let myDate= newdate.toLocaleDateString('es-MX')
         return(
            <tr key={ticket._id}>
              <th scope='row'>{ticket._id.substr(-5,5)}</th>
              <td>{ticket.UserName}</td>
              <td>{ticket.Content.substr(0,55)}</td>
              <td>{myDate}</td>
            </tr>   
          )} ) }  
      </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
        <Container className='d-flex justify-content-center'>
        <Button variant='secondary' onClick={props.onHide}>Cerrar Ventana</Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

const TicketShow=(props)=> {
  const {showTickets,setShowTickets}=useAuth();
  const [modalShow, setModalShow] = useState(true);// change to false when its button activated 
  return (
    <>
      <MyVerticallyCenteredModal
        alltickets={props.alltickets}
        alldone={props.doneTickets}
        show={modalShow}
        onHide={() => {setModalShow(false);
          setShowTickets(!showTickets); // avoids false clicks since we are using context 
    }
        }
      />
    </>
  );
}



export default TicketShow;