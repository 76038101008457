import React, {useState,useEffect} from 'react'
import { Col, Container, Row, } from 'react-bootstrap'
import "./Style.css"
import useAuth from '../../components/Hooks/useAuth'
import CardProd from '../../components/ProductCard/CardProd'
import Filter from '../../components/ProductFilter'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'

const Catalog = () => {
  // const [isBrand,setIsBrand]=useState(false)
  // const [categorias,setCategorias]=useState([]) //left for future request
    const { allSelect,cartDispatch,
        products,mybrand,
        productState:{byName,Query,byBrand}

}=useAuth();

useEffect (()=>{


},[products,byName,Query])
  
const TransProducts = ()=>{
   let sortedProducts=[]
  if (mybrand === null){
    allSelect === false  ? ( Query? (    
      sortedProducts=products.filter(product=>
      {return (product.Category.toLowerCase().includes(Query) || product.Brand.toLowerCase().includes(Query))} )
     
    ):(  sortedProducts=products.filter(x=>{return byName.find(c=>c.Name === x.Category)})) //Creating filter iterable based on another array getting Name form reducer and Cateory for original Array
  ):(<></>)

  }
  else{
    sortedProducts=products.filter(product=>product.Brand === mybrand)
  }


    return sortedProducts
}


const HandleCart=(Id)=>{
    let filtering=products.filter(product=>product._id === Id._id)
  // this auxiliary array is needed since we are getting only one item from the DB 
    let toCart=filtering[0] 
  console.log(toCart._id, toCart.Brand, toCart.Name);
  
    cartDispatch({
      type:"ADD_PRODUCT",
      payload:{
        Id:toCart._id,
        Brand:toCart.Brand,
        Name:toCart.Name,
        Picture:toCart.Picture
      }
    })
  }
  


  return (
    <div>
        <Header/>
        <NavBar/>
        <Row className='mt-5'>
            <Col md={2} className="ms-5 categories" style={{ textTransform:'uppercase'}}>
               <Container fluid >
               <h1 style={{ fontSize:'14pt', fontWeight:'bold' }}>Líneas de Producto</h1>
               <Filter/>
               </Container>     
            </Col>
            <Col md={9}>
                
                <Row className=
                'ProdGrid'>
                    {TransProducts().map(({_id,Category,Brand,Model,DataSheet,Datasheet,Picture,DescripcionCorta,DescripcionLarga})=>{
                    return(
                    <CardProd 
                        Key={_id}
                        category={Category}
                        image={Picture}
                        pdctName={Model}
                        desc={DescripcionCorta}
                        descLg={DescripcionLarga}
                        marca={Brand}
                        techdata={DataSheet}
                        pagedata={Datasheet}
                        
                        />
                    )
                })}
                </Row>
            </Col>
        </Row>

    </div>
  )
}

export default Catalog