import React, {useEffect} from 'react'
import { Container} from 'react-bootstrap'
import Slide from '../../components/Carousel'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'
import Slider from '../../components/Swiper'
import Brand from '../../components/Brands'
import Slogan from '../../components/Slogan'
import Cadena from '../../components/ValueChain'
// import CookieLabel from '../../components/CookieLegend'
import Contact from '../contact'
import Support from '../support'
import Values from '../../components/CompValue'
import useAuth from '../../components/Hooks/useAuth'
import {scroller} from 'react-scroll'

const Inicio = () => {
    const {scrollActive,setScrollActive}=useAuth();
 
    // Scroll from another page use Context to activate a flag and scroll once the flag is validated use of useEffect hook to check and render 
    // R.Diaz Dec-2022
    useEffect(()=>{
        if (scrollActive){
                scroller.scrollTo('nosotros',{
                    smooth:true,
                    offset:-70,
                    duration:500,
                  })
               setScrollActive(!scrollActive)
        }
    },[scrollActive])
  return (
<div>
    <Header/>
    <NavBar/>
    <Container fluid className='SlideCont'>
        <Slide/>
    </Container>
    <Container fluid className='swipeCont'>
    <Slider/>
    </Container>
 

<Slogan/>
<Values/>

<Brand/>
 

<Contact/>
<Cadena/>
<Support/>
{/* <CookieLabel/> */}
    
  
</div>

    )
}

export default Inicio