import React from 'react'


function FondoIT(props){
    return (
        <div style={{
                    backgroundImage: `url(https://earthtimes.org/newsimage/aurora_1_30112.jpg)`,
                    backgroundRepeat: 'no-repeat',        
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: 'auto',
                    minHeight:'100vh'
                    }}>
            {props.children}
        </div>
    );
}

export default FondoIT
