import React, {useState} from 'react'
import { Button, Modal,Table, Form, Container, FloatingLabel } from 'react-bootstrap'
import "./styleModal.css"
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'

const TicketAnswer = (props) => {
   
    const [show, setShow] = useState(true);
    const [response,setResponse]=useState("");
    const [ticketStatus,setTicketStatus]=useState(false)
    const {updateOpenTicket,setUpdateOpenTicket}=useAuth()

    const HandleUpdateTicket=async(ID)=>{
        let date= new Date();
        const payload = {
            id:ID,
            VendorResp:response,
            CloseStatus:ticketStatus,
            End:date
        }
        await ApiCall.UpdateTicket(payload)
        .then(res=>console.log(res))
        .catch(e=>console.log(e));
        await setUpdateOpenTicket(!updateOpenTicket)

    }
    


  return (
    <div>
        <Modal contentClassName='ModStyle' show={show} fullscreen={true} onHide={()=>setShow(!show)} onExited={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{color:'#FAB771'}}>Ticket # {props.data._id.substr(-5,5)}  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive variant='dark'>
                    <thead>
                        <tr>
                            <th>Nombre de Usuario</th>
                            <th>WhatsApp</th>
                            <th>Asunto</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    <tr>
                    <th>{props.data.UserName}</th>
                    <th>{props.data.WhatsappNum}</th>
                    <th style={{fontSize:'08pt'}}>{props.data.Content}</th>
                    </tr>
                    </tbody>
                </Table>
                <Container className='d-flex justify-content-center' style={{padding:'0px, 0px'}} > 
                <FloatingLabel
                className='mb-3'
                controlId='floatingTextarea'
                label='Respuesta al Ticket:'
                style={{color:'#FAB77140', fontSize:'16pt',textShadow:'1px 1px #E38119',fontWeight:'bold'}}
                >
                <Form.Control size='lg' style={{height:'50vh', width:'100vw',padding:'0px, 0px',backgroundColor:'#dcdcdc08', color:'#FAB771'}}
                as="textarea" aria-label="With textarea" placeholder='Respuesta al Ticket:'
                value={response}
                onChange={(e)=>setResponse(e.target.value)}
                />
                </FloatingLabel>
                </Container> 
            </Modal.Body>
            <Modal.Footer>
                <Container className='d-flex justify-content-center'>
            <Form.Check inline type='radio' label='Cerrar Ticket' id='close' onChange={()=>setTicketStatus(!ticketStatus)} /> 
                <Form.Check inline type='radio' label='Mandar a WhatsApp' id='close' />             
                </Container>
                <Button className='m-4' variant='warning' style={{backgroundColor:'#FAB771'}} onClick={()=>HandleUpdateTicket(props.data._id)}>Guardar Cambios</Button>
                <Button className='me-5' onClick={()=>setShow(!show)}> Exit</Button> 
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default TicketAnswer