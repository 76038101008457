import React, {useState} from 'react'
import { Navbar,Nav,NavDropdown, Container, Dropdown, NavLink } from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import useAuth from '../Hooks/useAuth'

const NavBar = () => {
  const Navigate=useNavigate();
  const [show,setShow]=useState(false)
  const [muestra,setMuestra]=useState(false)
  const {solutions,scrollActive,setScrollActive,productDispatch,setSolution, products,productState:{byName}}=useAuth();
  const allCat= products.map(product=>product.Category)
  const Items= [...new Set(allCat)];
  const allSol= solutions.map(sol=>sol.Category)
  const SolutionNames=[...new Set(allSol)]

    const  Categories=["Sistemas de Interconexión a la Red","Sistemas Aislados a la Red", "Bombeo Solar","Calentadores Solares",
"Iluminación","Refrigeradores"]

const showMenu = (e)=>{
  setShow(!show);
}
const hideMenu = e => {
  setShow(false);
}

const MuestraMenu= (e)=>{
  setMuestra(!muestra);
}
const esconde = e => {
  setMuestra(false);
}
// to manage change of page and scroll'im using useNavigate from React-Router-Domm Rdiaz Jan 2023 
const ManageScroll=()=>{
  setScrollActive(!scrollActive)
  Navigate('/home',{replace:true})
}


//change filter and go to products 
const HandleProductClick=(e)=>{
  productDispatch({
    type:"CLEAR_FILTERS"
  })

  productDispatch(
    { type:"SORT_BY_CATEGORY",
      payload:{Name:e.target.name}
    })
  Navigate('/products',{replace:true})
}

//solution handler
const HandleSolution=(e)=>{
  setSolution(e.target.name)
  Navigate('/soluciones',{replace:true})
}

  return (
    <Navbar expand="lg"  variant="dark" style={{ backgroundImage:'linear-gradient(to right, #1c2c44,#2e4560'}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" /> 
        <Navbar.Collapse id='basic-navbar-nav'>
            <Container fluid className='NavCont'>
        <Nav fill  defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link eventKey="link-1" onClick={()=>ManageScroll()}>Nosotros</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2" onClick={(e)=>{
          e.preventDefault();
          Navigate('/promociones',{replace:true})
          
        }}>Promociones</Nav.Link>
      </Nav.Item>x
      <Nav.Item>
       <NavDropdown title="Productos" id="nav-dropdown1" className="dklk" show={show} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
        {Items.map((productline)=>{
          return(
            <Dropdown.Item key={productline.id} name={productline} onClick={(e)=>HandleProductClick(e)} >{productline}</Dropdown.Item>
          )
        })}
       </NavDropdown>
      </Nav.Item>
      <Nav.Item>
      <NavDropdown title="Soluciones" id="nav-dropdown" className="dklk" show={muestra} onMouseEnter={MuestraMenu} onMouseLeave={esconde}>
              { Categories.map((category)=>{
                return(
               
                  <NavDropdown.Item Key={category.id} name={category} onClick={(e)=>HandleSolution(e)}>{category}</NavDropdown.Item>
                )
              })

              }
      </NavDropdown>
        
      </Nav.Item>
      <Nav.Item>
      {/* protected routes must use the href properties if we are using persistent login to avoid backdoors */}
        <Nav.Link eventKey="link-3" href='/supply'>Distribuidores</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-4"  onClick={(e)=>{
          e.preventDefault();
          Navigate('/soporte',{replace:true});
        }}>Soporte</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-5"  onClick={(e)=>{
          e.preventDefault();
          Navigate('/contacto',{replace:true});
        }}>Contacto</Nav.Link>
      </Nav.Item>
    </Nav>
    </Container>
        </Navbar.Collapse>
    </Navbar>
  )
}

export default NavBar