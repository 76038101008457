import React from 'react'
import Bkgnd from '../../components/bkgImg/Fondo2'
import CatlogEdit from '../../components/CatlogEdit'

const EditCatalog = () => {
  return (
    <Bkgnd>
        <CatlogEdit/>
    </Bkgnd>
  )
}

export default EditCatalog