//calls non related to authentication 
import axios from 'axios'
import useAxiosPrivate from '../components/Hooks/useAxiosPrivate'


const ApiCall = {
 
    AllMat:()=>{
       console.log("getting everything");
        return axios.get("/Items/All")
    },
    FindUser:(payload)=>{
        console.log(payload);
        return axios.post("/user/findID",payload)
    },
    AddMaterial:(payload)=>{
        return axios.post("/comp/new",payload);
    }, 
    AddRFQ:(payload)=>{
        return axios.post("/quotes/new",payload)
    },

    AllQuotes:()=>{
        return axios.get("/quotes/all")
    },
    
    Register:(Payload)=>{
        return axios.post('/user/new',Payload)
    },
    CreateTicket:(Payload)=>{
        return axios.post('/Tickets/create',Payload)
    },
    TodosUsuarios:(req,res)=>{
        const AxiosPrivate=useAxiosPrivate()
        return AxiosPrivate.get("/user/Allusers")
        .then(res=>res.data)
        .catch(e=>console.log(e));

    },
    UserRemover:(payload)=>{
        return axios.delete('/user/findID/'+ payload)
    },
    FindByID:(payload)=>{
        return axios.get('/user/findID/'+ payload)
    },
    UpdateUser:(Payload)=>{
        return axios.put('/user/findID',Payload)
    }, 

    AllTickets:()=>{
        return axios.get('/Tickets/all')
    },
    QuoteByID:(payload)=>{
        return axios.post('/quotes/'+ payload)
    },

    UpdateQuote:(payload)=>{
        return axios.put('/quotes/'+payload.id
        , payload)
    },
    TicketById:(payload)=>{
        return axios.post('/Tickets/'+payload)
    },
    UpdateTicket:(payload)=>{
        return axios.put('/Tickets/'+payload.id,payload)
    },
    GetTicketByLast5:(payload)=>{
        return axios.post('/Ticket/search'+ payload)
    },
    SendMail:(payload)=>{
       return axios.post('/mailrst/',payload)
      },
    GetSolutions:()=>{
        return axios.get('/AllSolutions')
    },
    AddPromo:(payload)=>{
        return axios.post('/Items/Promo',payload)
    },
    AllPromos:()=>{
        return axios.get('/Items/Promo/All')
    },
    UpdatePromo:(payload)=>{
        return axios.put('/Items/Promo/'+payload._id, payload)
    },
    RemovePromo:(payload)=>{
        return axios.delete('/Items/Promo/'+ payload)
    },
    AddProduct:(payload)=>{
        return axios.post('/Items/product/',payload,{ headers:{"Content-Type":"multipart/form-data"}})// headers added per multer documentation 
    },
    UpdateProduct:(payload)=>{
        // return axios.put('/Items/product/'+ payload._id, payload)
        return axios.put('/Items/update/',payload,{ headers:{"Content-Type":"multipart/form-data"}}) // headers added per multer documentation 
        
    },
    RemoveProduct:(payload)=>{
        return axios.put('/Items/remove/',payload)
    },

    AddDistributor:(payload)=>{
        return axios.post('/dist/new',payload)
    },
    ModDistributor:(payload)=>{
        return axios.put('/dist/single/'+payload._id, payload)
    },
    deleteDist:(payload)=>{
        return axios.delete('/dist/single/'+payload._id)
    },

    GetAllCust:()=>{
        return axios.get('/cust/All')
    },

    NewImg:(payload)=>{
        return axios.post('/cust/new',payload)
    },

    UpdateCust:(payload)=>{
        return axios.put('/cust/'+payload._id,payload)
    },

    DelCust:(payload)=>{
        return axios.put('/cust/',payload)
    },

    UploadCustImg:(payload)=>{
        return axios.post("cust/NewImage", payload,{ headers:{"Content-Type":"multipart/form-data"}}) // headers added per multer documentation 
    },
    UploadImg:(payload)=>{
        return axios.post('image/upload',payload,{ headers:{"Content-Type":"multipart/form-data"}}) // headers added per multer documentation 
    },
    PosterUpload:(payload)=>{
        return axios.post('poster/upload',payload,{ headers:{"Content-Type":"multipart/form-data"}}) // headers added per multer documentation
    },
    GetPosters:()=>{
        return axios.get('poster/all')
    },
    RemovePoster:(payload)=>{
        return axios.put('poster/',payload)

    }


  
}

export default ApiCall