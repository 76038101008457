import React, { createContext, useState, useEffect,useReducer} from 'react'
import axios from 'axios'
import ApiCall from '../AxiosCalls';
// importing Reducers
import { cartReducer, productReducer,UserReducer,TicketReducer,RfqReducer,DistributorReducer} from '../components/Hooks/reducers';
 const AuthContext = createContext({});

 export const AuthProvider = ({ children }) => {
     //hook to store the logged user profile 
     const [auth, setAuth]=useState({})
     const [reload,setReload]=useState(false)
     // state of persist to keep the user logged in if they trust this device
     const [persist,setPersist]=useState(JSON.parse(localStorage.getItem("persist")) || false);
     //hook for sub profile to store name and copany of the current logged user
     const [currentuser, setCurrentuser]=useState({});
     const [profilemodal,setProfilemodal]=useState(false)
     //this context is for develoment only for deployment we need to call to db
     const [products,setProducts]=useState([])
     const [allPromos,setAllPromos]=useState([])
     const [mybrand,setMybrand]=useState(null)
     const [solutions,setSolutions]=useState([])
     const[allSelect,setAllSelect]=useState(false);
     const[currentCategory,SetCurrentCategory]=useState([])
     const [privacy,setPrivacy]=useState(false)
     const [productDet, setProductDet]=useState(false); // state to controll offcanvas called from product card
     const [singleProd,setSingleProd]=useState({});
     const [scrollActive,setScrollActive]=useState(false) 
     const [terms,setTerms]=useState(false);
     const [editUser,setEditUser]=useState(false)
     const [editVendor,setEditVendor]=useState(false)
     const [solution,setSolution]=useState(null)
     const [rfq,setRfq]=useState([]);
     const [showQuotes,setShowQuotes]=useState(false)
     const [singleQuote,setSingleQuote]=useState(false);
     const [singleTicket,setSingleTicket]=useState(false)
    const [showTickets,setShowTickets]=useState(false);
    const [selectUpdate,setSelectUpdate]=useState(''); //variable to update profile at modals/update profile
    const [updateOpenRfqs,setUpdateOpenRfq]=useState(false);// this state is to trigger the useEffect when answering quotes
    const [updateOpenTicket,setUpdateOpenTicket]=useState(false);// this state is to trigger the useEffect when answering Tickets 
     //declaring reduces for cart my state and dispatch are called cartstate and cartdispatch
     const [cartState,cartDispatch]=useReducer(cartReducer,{
        product:products,
        cart:[]
     })
     //declaring reducer for filter
     const[productState,productDispatch]=useReducer(productReducer,{
        byCategory:false,
        searchQuery:"",
        byName:[],
        byBrand:[]
     })
     //Declaring reducer for users 
     const [userState,userDispatch]=useReducer(UserReducer,{
        username:"",
        FirstName: "",
        LastName: "",
        email:"",
        password:"",
        company:"",
        SellerID:"63a5d672603933ec891e0a3a" // this is the super user seller ID Jose Valdez use as a default seller
        
    })
     //Declaring reducer for ticket 
     const [ticketState,ticketDispatch]=useReducer(TicketReducer,{
        UserName:"",
        Email:"",
        Content:"",
        WhatsappNum:""       
    })

// Declaring reducer for new distributors 
const [distributorState,distributorDispatch]=useReducer(DistributorReducer,{
    Company:"",
    ContactName:"",
    WhatsAppNum:"",
    Email:"",
    City:""

})


//Rfq Reducer
const [cotiState,cotiDispatch]=useReducer(RfqReducer,{
    coti:[]
 })


//attempting to call the db when the app is loading 
     useEffect(()=>{   
        AllSolutions();
        getAllPromos();
     },[])

     //separated call to the main db to refresh only all products 
     useEffect(()=>{
        CallDb();
      
     },[reload])

// here are the sub routines executed when loading

const AllSolutions=async()=>{
    const Solutions=await axios.get("/AllSolutions")
    .then(res=>res.data)
    .catch(e=>console.log(e))
    setSolutions(Solutions)
}
     const CallDb=async()=>{
        const items= await axios.get('/Items/All')
        .then(res=>{return (res.data)})
        .catch(e=>console.log(e))
        setProducts(items)
    }

    const getAllPromos=async()=>{
        await ApiCall.AllPromos()
        .then(res=>setAllPromos(res.data))
        .catch(e=>console.log(e))          
    }

   

    return (
        <div>
            <AuthContext.Provider value={{currentCategory,SetCurrentCategory, allSelect,setAllSelect,cartState,cartDispatch,productState,
            productDispatch,auth, setAuth, privacy,setPrivacy,productDet, setProductDet,singleProd,setSingleProd, currentuser, 
            setCurrentuser,products,setProducts,userState,userDispatch,ticketState,ticketDispatch,scrollActive,setScrollActive,
            solution,setSolution,editUser,setEditUser,editVendor,setEditVendor,terms,setTerms,cotiState,cotiDispatch,reload,setReload,
            rfq,setRfq,showQuotes,setShowQuotes,showTickets,setShowTickets,singleQuote,setSingleQuote,updateOpenRfqs,setUpdateOpenRfq,
            updateOpenTicket,setUpdateOpenTicket,singleTicket,setSingleTicket,solutions,setSolutions,mybrand,setMybrand,
            allPromos,setAllPromos,persist,setPersist,profilemodal,setProfilemodal,selectUpdate,setSelectUpdate,distributorState,distributorDispatch}}>
                { children }
            </AuthContext.Provider>
        </div>
    )
}

export default AuthContext