import React,{useState} from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'
import ProductShow from '../ProductDetail';
import {useNavigate} from 'react-router-dom'
import { Link } from 'react-router-dom';

const SolCard = (props) => {
  const {productDispatch}=useAuth(); 
    const Navigate=useNavigate();

  const HandleClick=(e)=>{

    productDispatch({
      type:"CLEAR_FILTERS"
    })
  
    
    productDispatch(
        { type:"SORT_BY_CATEGORY",
          payload:{Name:e}
        })
      Navigate('/products',{replace:true})
  }

  return (
   
        <Card style={{width: '13rem', margin:'0px 20px 55px 0px'}}>
            <Card.Body>
                <Card.Title>{props.category}</Card.Title>
                <Card.Img variant='top' style={{ height:'4rem', width:'auto'}} src={props.image} />
                <Card.Subtitle className='mt-2'>{props.desc}</Card.Subtitle>
            </Card.Body>
            <Card.Footer className='cardFoot mb-0'>
                    <span className='me-3 ms-1'>
                    <a href onClick={(e)=>HandleClick(props.category)} name={props.category}><i className="fa-duotone fa-glasses fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#f2a854"}} ></i></a>
                    </span>
                </Card.Footer>
        </Card>
  )
}

export default SolCard