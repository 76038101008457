import React from 'react'
import FondoIT from '../../components/bkgImg/HDImage'

const TicketChat = () => {
  return (
   <FondoIT>
<h1> Hello</h1>

   </FondoIT>
  )
}

export default TicketChat