import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { faker } from '@faker-js/faker'
  import { Bar } from 'react-chartjs-2';
  

  ChartJS.register(
    CategoryScale,
    LinearScale,
    // PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  


const ChartInv = () => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };

      const labels = ['Ene', 'Feb', 'Mar', 'Abr', 'May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];

      const data = {
        labels,
        datasets: [
          {
            label: '2021',
            // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            data: labels.map(() => faker.datatype.number({ min: 1000, max: 50000 })),
            backgroundColor: '#3FDB1D',
          },
          {
            label: '2022',
            data: labels.map(() => faker.datatype.number({ min: 1000, max: 75000 })),
            backgroundColor: '#F52323',
            // backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: '2023',
            data: labels.map(() => faker.datatype.number({ min: 1000, max: 35000 })),
            backgroundColor: '#1316E8',
          },
        ],
      };



  return (
    <div>
        <Bar options={options} data={data}  />


    </div>
  )
}

export default ChartInv