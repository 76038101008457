import React, {useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row,Container, Card,Col, Button,Form} from 'react-bootstrap'
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'
import RFQbyUSER from '../Modals/RfqByUser'
import useAxiosPrivate from '../Hooks/useAxiosPrivate'



const Ventas = () => {
    const AxiosPrivate=useAxiosPrivate();
    const Navigate=useNavigate();
    const [usuarios,setUsuarios]=useState(null)
    const [user,setUser]=useState("")
    const [clientes,setClientes]=useState([])
    const [vendedores,setVendedores]=useState([])
    const [quotes,setQuotes]=useState([])
    const [userQuotes,setUserQuotes]=useState([])
    const {rfq,showQuotes,setShowQuotes,singleQuote}=useAuth()
   

    useEffect(()=>{
    CallUsers();
    CallQoutes(); // calls function to retrieve quotes and filters the ones are open
    },[singleQuote]) // useeffect is fired when singleQuote fires this is triggered on the second modal 

    const CallUsers=async()=>{
      const items= await AxiosPrivate.get("/user/Allusers", {withCredentials:true})
      let users=items.data
      setUsuarios(items.data)
      setClientes(users.filter(u=> u.profile ==='Cliente'))
      setVendedores(users.filter(v=>v.profile ==='Vendedor'))
      return items
  }


const CallQoutes=async()=>{
 await ApiCall.AllQuotes()
  .then(res=>{ 
    setQuotes(res.data.filter(q=>q.CloseStatus === false))
  })
  .catch(e=>console.log(e))
} 

    const HandleSelect=()=>{
    setShowQuotes(!showQuotes);
    let filtered= quotes.filter(q=>q.UserID === user)
    setUserQuotes(filtered)
    }

  return (
    <div>
         <Row>
        <Container fluid className='mt-2' style={{fontSize:'16pt',fontWeight:'bold'}}> Bienvenido a tu página de Ventas</Container>
      </Row>
      <Row>
        <Container style={{backgroundColor:'transparent'}} className=" mt-5 d-flex justify-content-center">
        <Card style={{width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='d-block  justify-text-center'>
            <Card.Title>Cotizaciones</Card.Title>
            <Card.Body>
              { quotes ? ( <Container className=' pt-2 d-flex justify-content-center'>
          Cotizaciones Abiertas : {quotes.length}
            </Container>    
                ):(<></>) }

        { clientes ? (<>
                Clientes Registrados : {clientes.length}
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectClient"        
          onChange={(e) => {
            setUser(e.target.value)
          }}
          >
            <option>Selecciona un cliente</option>
       {clientes.map((c)=>(             
                  <option key={c._id} value={c._id}> {c.FirstName} {c.LastName}</option>
                ))}
          </Form.Select>
            </Container>    
                </>):(<></>)
              }



            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={()=>HandleSelect()}>Ver Cotizaciones</Button>
            </Card.Footer>
          </Card>
          {showQuotes && (<RFQbyUSER filteredquotes={userQuotes} client={user} />)}
       
        </Container>
      </Row>
      <Row>
        <Container fluid>
          <Button variant='secondary' style={{width:'12rem',marginTop:'8rem'}} onClick={()=>Navigate('/home',{replace:true})} >Salir</Button>
        </Container>
      </Row>
    
    </div>
  )
}

export default Ventas
