import React from 'react'
import Fondo from '../../components/bkgImg'
import TicketCard from '../../components/Card/TicketCard'
import Header from '../../components/Header'
import NavBar from '../../components/Nav/NavBar'
import Support from '../support'
import TicketFrm from '../../components/SoporteFrm'

const Soporte = () => {
  return (
   <Fondo>
    <Header/>
    <NavBar/>
    <TicketCard className='mt-5'>
        <TicketFrm/>
    </TicketCard>
    <footer>
        <Support/>
    </footer>

   
   </Fondo>
  )
}

export default Soporte