import React, {useEffect,useState} from 'react'
import {Container,Row, Col, Form,Button,InputGroup} from 'react-bootstrap'
import {useNavigate, redirect } from 'react-router-dom'
import logo from '../images//SolarMax.png'
import './style.css'
import useAuth from '../Hooks/useAuth';
import ApiCall from '../../AxiosCalls'
import ProfileControl from '../Popovers/profile'
import ProfileUpdate from '../Modals/UpdateProfile'

const Header = () => {
  //using "Goto" as a navigation variable using react-router-dom
const Goto=useNavigate();
const {profilemodal,cartState:{cart},productState:{Query,byBrand},
productDispatch,setSolution,setMybrand,auth,currentuser,
setCurrentuser,products,persist}=useAuth();
const [target, setTarget] = useState(null);
const [showProfCtr,setShowProfCtr]=useState(false);
const Marcas=products.map(product=>product.Brand)
const Brands=[...new Set(Marcas)]



useEffect(()=>{
  auth?.user ? (getUSerInfo()):(console.log("Nobody logged-in yet"))
},[])



const getUSerInfo= async()=>{
  const userinfo= await ApiCall.FindByID(auth.user._id)
  .then()
  .catch(e=>console.log(e))
  setCurrentuser(userinfo.data)
}


    //solution handler
    const HandleSolution=(e)=>{
     
      setMybrand(e.target.value)
      Goto('/products',{replace:true})
    }


    const HandleNavigate=(e)=>{
      e.preventDefault()
      Goto('/supply',{replace:true})
    }

    //adding listener to profilemodal state to install a cleanup 
    //for the profile controller
    useEffect(()=>{
      !profilemodal && setShowProfCtr(false)
    },[profilemodal])
   


  return (
<Container fluid >
    <Row className='Ribbon3'>
               <Col md={2} lg={2}>
               <a  href onClick={()=>{Goto('/home',{replace:true})}}>
          <img className="logomain" src={logo} alt="logo"></img>
        </a>
        </Col>
        <Col  xs={12} sm={8} md={7} lg={6}  >
        {/* <Col> */}
          <Container fluid className="SearchBar">
          <InputGroup className="mb-3">
        <Form.Select aria-label="Selecciona" onChange={(e)=>HandleSolution(e)} >
      <option>Selecciona por marca </option>
      {Brands.map((brand)=>{
        return( 
          <option Key={brand.id} value={brand} >{brand.toUpperCase()}</option>
        )
      })}
    </Form.Select>
        <Form.Control
          placeholder="  Busca por palabra ej: Baterías "
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          className='SearchField'
          onChange={(e)=>{
            let val=e.target.value.toLowerCase();
            productDispatch({
              type:"FILTER_BY_SEARCH",
              payload:val
            }
            );
            Goto("/products",{replace:true});
          }}
        />
        <Button variant="outline-secondary" className='SearchButton' id="button-addon2"
        onClick={()=>{Goto("/products",{replace:true})}} >
          
        <i className="fa-duotone fa-magnifying-glass" style={{color:'#2c2c2c'}}></i>
        </Button>
      </InputGroup>
      </Container>              
        </Col>


        {/* here the last of three columns  */}
        <Col  xs={12} md={2} lg={4}  >
          <Row className='ms-2 me-4'>
           <Col className='text-end PhoneLegend p-2'>
           {auth?.isAuthenticated || persist
           ? ( 
            
            <a className='anchor' href onClick={(e)=>HandleNavigate(e)}><i className="fa-duotone fa-cart-shopping fa-lg" ></i>
      <span className='ps-2' style={{fontSize:'10pt'}}>{cart.length}</span></a>
     
          ):(<></>)}
            </Col>
           <Col className='text-end PhoneLegend p-2'>
           { auth?.isAuthenticated || persist
                ?  (
                <a className='anchor' href onClick={(e)=>{
                    setShowProfCtr(!showProfCtr);
                    setTarget(e.target);
                    }}><i className="fa-solid fa-user "></i><span style={{fontSize:'.8rem'}}>{currentuser.FirstName}<br/><h4 className='SecondaryLegend'>{currentuser.LastName}</h4></span>
                    </a>
                    )
                :
                 (<a className='anchor text-right' href='/Login'>
                    <i className="fa-solid fa-user "></i>
                    <span style={{fontSize:'.8rem'}}>INICIO DE SESIÓN<br/><h4 className='SecondaryLegend ms-5'>Registro</h4></span>
                    <div>&nbsp;</div>
                  </a>)}
            
              {showProfCtr && <ProfileControl target={target} user={currentuser} />}
           </Col>
           <Col xs={12} md={6} lg={4} className='text-end PhoneLegend p-2'>
           <a className='anchor' rel="noopener noreferrer" href='https://wa.me/526142471348'>
              <i className="fa-brands fa-whatsapp fa-lg "></i>
              <span style={{fontSize:'.8rem'}}>+52(614).247.1348</span> 
              </a>
           </Col>
           <Col  xs={12} md={6} lg={3} className='text-end PhoneLegend p-2'>
            <a className='anchor' rel="noopener noreferrer" href='https://calendar.app.google/SFCfjBztXFFefiCJA'>
              <i className="fa-duotone fa-calendar-clock fa-lg"></i>
              <span style={{fontSize:'.8rem'}}>Agenda Cita</span> 
              </a>
          
          </Col> 
          </Row>   
        </Col>
    </Row>
    {profilemodal && <ProfileUpdate/>}

    </Container>

 

    )
}

export default Header