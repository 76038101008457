import React,{useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useAuth from '../Hooks/useAuth';
import { Form,InputGroup} from 'react-bootstrap';
import "./styleModal.css"
import ApiCall from '../../AxiosCalls';

function MyVerticallyCenteredModal(props) {  
  const [muestra,setMuestra]=useState(false)
  const [passone,setPassone]=useState('')
  const [passtwo,setPasstwo]=useState('')
  const [newpass,setNewpass]=useState('')
  const [payload,setPayload]=useState({})
  const [message,setMessage]=useState('')
  const {setProfilemodal}=useAuth()


  //this useeffect will listen for changes on newmail and new password states and complete the function 
  useEffect(()=>{
    getpayload()
  },[newpass])

  useEffect (()=>{
    (passone === passtwo)
    ?setNewpass(passone)
    : setMessage('Error en Contraseña');
  },[passtwo])


  const HandleUpdate=async()=>{
    // console.log("paused");
    await ApiCall.UpdateUser(payload)
        .then(res=>{
          if (res.status===200){alert('Actualizacion exitosa!')}
        })
      .catch(e=>console.err(e))
      setProfilemodal(false);
      }
      

  const getpayload=async()=>{
    await ApiCall.FindUser({email:props.email})
    .then(res=>setPayload({id:res.data._id, password:newpass}))
    .catch(e=>console.log(e)) 
  }

  const HandleHide=(e)=>{
    e.preventDefault();
    setMuestra(!muestra)
}




  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle' 
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Establecer una contraseña nueva
        </Modal.Title>
      </Modal.Header>


  
   <Modal.Body>
    <Form>
    <Form.Label>Email actual : { props.email}</Form.Label>   
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Ingresa tu nuevo password</Form.Label>
            <InputGroup>
            <Form.Control type={muestra? ("text"):("password")} 
            onChange={(e)=>setPassone(e.target.value)} rows={1} />
            <InputGroup.Text style={{backgroundColor:'#FFFFFFDD', color:'purple'}}>
                                    <i onClick={HandleHide} className={muestra? ("fa-duotone fa-eye-slash"):("fa-duotone fa-eye")}></i>
                                    </InputGroup.Text>
                                </InputGroup>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Verifica tu password</Form.Label>
            <InputGroup>
            <Form.Control type={muestra? ("text"):("password")}  
            onChange={(e)=>setPasstwo(e.target.value) } rows={1} />
            <InputGroup.Text style={{backgroundColor:'#FFFFFFDD', color:'purple'}}>
                                    <i onClick={HandleHide} className={muestra? ("fa-duotone fa-eye-slash"):("fa-duotone fa-eye")}></i>
                                    </InputGroup.Text>
                                </InputGroup>
          </Form.Group>
        </Form>
    </Modal.Body>
    
      <Modal.Footer>
        { newpass 
          ? <p className='position-absolute start-0 ms-3' style={{color:'green', textAlign:'center',fontFamily: "Hanken-Book"}}> las contraseñas coinciden</p> 
          :  <p className='position-absolute start-0 ms-3' style={{color:'yellow', textAlign:'center', fontFamily: "Hanken-Book"}}> Las contraseñas no son iguales </p>         
        }
        <Button variant="secondary" onClick={props.onHide}>Salir</Button>
          <Button variant="primary" onClick={()=>HandleUpdate()}  >
            Guardar Cambios
          </Button>
          {/* {message && <p style={{color:'yellow', textAlign:'center', fontFamily: "Hanken-Book"}}>{message}</p>} */}
      </Modal.Footer>
    </Modal>
  );
}
const PassReset=(props)=> {
  const [modalShow, setModalShow] = React.useState(true);// change to false when its button activated 
  const {setProfilemodal}=useAuth();

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        email={props.email}
       
        onHide={() => {setModalShow(false);
            setProfilemodal(false); 
    }
        }
      />
    </>
  );
}

export default PassReset