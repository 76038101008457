import React, {useRef,useState,useEffect} from 'react'
import {Form, Button} from "react-bootstrap"
import  { PinInput } from 'react-input-pin-code'
import useAuth from '../Hooks/useAuth'
import ApiCall from '../../AxiosCalls'
import "../LogFrm/style.css"
import PassReset from '../Modals/ResetPass'

const Askreset = () => {
const EmailRef= useRef();
const [sent,setSent]=useState(false)
const [emailInput, setEmailInput] = useState("");
const [otp,setOtp]=useState(1111)
const [values, setValues] = useState(['', '', '','']);
const {profilemodal, setProfilemodal,setSelectUpdate}=useAuth();
const MailMessage= ['Se ha enviado un código de seguridad a tu correo, si no lo encuentras revisa el folder de Correo No Deseado (SPAM)',
                    'La dirección de correo no es válida o no está registrada',
                'El Código de accesso no es valido, solicita un código de accesso nuevo'] 


        useEffect(()=>{  
            OtpGen();
                        },[sent])

    const OtpGen=()=>{ 
        setOtp(Math.floor(Math.random()*(8000))+1000)
    }

const HandleSubmit=(e)=>{
    e.preventDefault();
    setSent(!sent)
    console.log(otp);
    let input= values.concat().join('') // the first conversion to concat() will make an array of the four components then we use Array.join('') property to put all the number together 
    let bes=otp.toString()
   if( bes === input){ 
    setProfilemodal(true);
    setSelectUpdate('password')
}
else{
    alert(MailMessage[2])
} 
}

const HandleSendCode=async (e)=>{
    e.preventDefault()
    // need to check if the input email exist on our db 
    let payload={email:emailInput}
    await ApiCall.FindUser(payload)
    .then(res=>{ 
        if (res.data !== null) { 
            
            SendTheCode() 
        }
        else{
            alert(MailMessage[1])
        }
    })
    .catch(e=> {
        alert('Hubo un error ! Vuelve a intentarlo más tarde')
    });
        
      
}

const SendTheCode=async()=>{
    const payload={load:otp,email:emailInput}
    emailInput? (
         await ApiCall.SendMail(payload)
        .then(res=>{
            res.status===200? (alert(MailMessage[0])):(alert(MailMessage[1]))
        })
        .catch(err=>console.log(err))):(alert('Ingresa el email registrado en Solarmax'))
}


  return (

    <div className="masthead">
    <div className="color-overlay d-flex justify-content-center align-items-center">
     {/* <Form className="rounded p-4 p-sm-3" onSubmit={HandleSubmit} > */}
              <Form className="rounded p-4 p-sm-3" onSubmit={e=>HandleSubmit(e)} >
             <Form.Group className="mb-3" controlId="formBasicEmail">
                 <Form.Label>Correo Electrónico Registrado:</Form.Label>
                 <Form.Control type="email" 
                 placeholder="Ingresa tu correo" 
                 ref={EmailRef} 
                 value={emailInput} 
                 autoComplete="off"
                 onChange={(e)=> setEmailInput(e.target.value)}/>
                 <Form.Text className="dissclose">
                    Se te enviará un correo con un código de seguridad
                 </Form.Text>
             </Form.Group>
             <div className="d-grid gap-2">
                 <Button variant="outline-secondary" onClick={(e)=>HandleSendCode(e)} style={{backgroundColor:'#f5822a', color:'#2c2c2c'}} size="lg" type="submit" >
                      Enviar Código
                 </Button> 
             </div>
             <Form.Group className="mt-3 mb-3" controlId="formBasicPassword">
                 <Form.Label>Código de seguridad:</Form.Label>
             
              <div className="d-grid ms-2 gap-2">
                 <PinInput size='lg'
                    values={values}
                      onChange={(value, index, values) => setValues(values)}
                    />
    </div>       
             </Form.Group>
             <Form.Group className="mb-3" controlId="formBasicCheckbox">
                 <Form.Check type="checkbox" label="Acepto Términos y Condiciones" />
             </Form.Group>
             <div className="d-grid gap-2">
                 <Button variant="secondary" size="lg" type="submit" >
                      Reestablecer
                 </Button> 
             </div>
  </Form>
 </div>
 {profilemodal && <PassReset email={emailInput}/>}
 </div>
  )
}

export default Askreset