import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Promo from '../images/MainPage/Posters/astronergy.png'
import Promo2 from '../images/MainPage/Posters/einovaSolarine.png'
import Promo3 from '../images/MainPage/Posters/LIVOLTEK2.png'
import '../../Styles/style.css'
import ApiCall from '../../AxiosCalls';

const Slide = () => {
 
    const [index, setIndex] = useState(0);
    const [posters,setPosters]=useState([])

    useEffect (()=>{
      AllPosters();
    },[])

    // routine to call all posters from db
    const AllPosters=async()=>{
      const allImg= await ApiCall.GetPosters()
      .then(res=>res.data)
      .catch(e=>console.log(e));
      setPosters(allImg)
      
    }
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (   
          <Carousel activeIndex={index} onSelect={handleSelect}>
            { posters === null ? "": posters.map(({_id,picture})=>{
              return(
                <Carousel.Item key={_id} interval={20000}>
                <img  className="d-block w-100 SlideImg" src={picture} alt="First" />
              </Carousel.Item>

              )
            })}            
       
      </Carousel>  
  )
}

export default Slide