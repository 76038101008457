import React from 'react'
import './value.css'
import { Container } from 'react-bootstrap'
import ValueChain from '../../components/images/MainPage/NEWCadena.png'

const Cadena = () => {
  return (

    <Container fluid className='Cadena'>

      <h1 className='legendValue'> Cadena De Valor</h1>
   
      <img src={ValueChain} alt="valuehcain" className='img-fluid'></img>
      </Container>
    
  )   
}

export default Cadena