import React from 'react'
import { Container,Row,Button} from 'react-bootstrap'
import logoBW from "../../components/images/MainPage/LogoBlanco.png"
import Privacidad from '../../components/Modals/Privacy'
import Terminos from '../../components/Modals/TdS'
import useAuth from '../../components/Hooks/useAuth'


import './support.css'

const Support = () => {
  const [term,setTerm]=React.useState(false)
  const {privacy,setPrivacy}=useAuth();

  const HandlePrivacy=(e)=>{
    e.preventDefault();
    setPrivacy(!privacy)

  }



  return (
    <div>
        <Container fluid className='RibbonFooter'>
            <Row className="justify-content-center">
          <img className="logobott" src={logoBW} alt="SolarMax"/>
            </Row>
            <div className="BotRow">
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.facebook.com/solarmaxenergiaMX'>
                        <i className="fa-brands fa-facebook fa-6x Cfacebook"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.instagram.com/solarmaxcuu/'>
                    <i className="fa-brands fa-instagram fa-6x Ficon"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.linkedin.com/company/solarmax-energia/'>
                    <i className="fa-brands fa-linkedin fa-6x Ctwitter"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.youtube.com/@solarmaxenergia1732'>
                    <i className="fa-brands fa-youtube fa-6x Ctube"></i></a>
                </span>
            </div>
            
        </Container>

        <Container fluid className='privacy-tos'>
        
        
        <div className='footer-links'>
        
          <a href="#" onClick={HandlePrivacy}>PÓLITICA DE PRIVACIDAD</a>
          {privacy && (<Privacidad/>) } 
          
          <a href="#" onClick={()=>setTerm(!term)}>TERMINOS DE SERVICIO</a>
          {term && (<Terminos/>)} 
         
         

        </div>
       
        <Row className='LastRibbon'>
          
          <div className='copyright'>
          Derechos Reservados Roccia Global 2024
        </div>
        </Row>
        
        <div  className="BlueCorner">
          {/* <Button className="BlueCorner">text</Button> */}
          <Button className='Corner'>
            <h4 className='ILabel mt-2'>Contacto:
           
            </h4>
            <div className='Contenido'>
            <p>
           <img className='logoBw'src={logoBW}></img> 
           <br/>
           <h1 className='mt-3'>contáctanos</h1>
           +52 (614) 247-1348
            ventas1@solarmaxenergia.com
            <br/>
            <Button className='ClickWhats mt-4'>
            <a target="_blank" style={{color:'#ffffff'}} rel="noopener noreferrer" href='https://wa.me/526142471348'>
              <i class="fa-brands fa-whatsapp"></i><span>envia whatsapp</span></a></Button>

             </p>
             </div>
            </Button>
          </div>
        </Container>    
    </div>
  )
}

export default Support