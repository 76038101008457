import React from 'react'
import Bkgnd from '../../components/bkgImg/Fondo2'

import Marketing from '../../components/Mkt'

const MercadoTecnia = () => {
  return (
    <Bkgnd>
        <Marketing/>
    </Bkgnd>
  )
}

export default MercadoTecnia