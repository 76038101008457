import React from 'react'
import LoginFrm from '../../components/LogFrm'
import Fondo from '../../components/bkgImg'
import Tarjeta from '../../components/Card'



const Login = () => {
  return (
    <Fondo>
      <div className="sign_form">
        <Tarjeta>
          
        <LoginFrm/>

        </Tarjeta>


      </div>
      
    </Fondo>
    
  )
}

export default Login