import React from 'react'
import FondoIT from '../../components/bkgImg/HDImage'
import Header from '../../components/Header'
import Support from '../support'
import Help from '../../components/Help'

const HelpDesk = () => {
  return (
    <FondoIT>
        <Help/>
    </FondoIT>
  )
}

export default HelpDesk