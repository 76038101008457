import React from 'react'
import {Container,Col, ListGroup, Tab, Row} from 'react-bootstrap';

import './style.css'
const Values = () => {
    

    const OurVal={
        Nosotros:"Grandes cambios se han generado en más de 30 años en el mercado solar en todo el país. SOLARMAX, ha sido testigo de cada uno de ellos.  Al igual que el mercado, la empresa también ha realizado cambios significativos en su esquema de trabajo. Prácticamente se ha reinventado para centrarse por completo a la venta de mayoreo de equipos y componentes de energía solar.",
        Mision:"Proveer soluciones de energías renovables con productos de alta calidad, servicios especializados y tecnologías emergentes.",
        Vision:"Lograr relaciones exitosas con los clientes y socios comerciales a través de la innovación, capacitación continua y compromiso con el medio ambiente.",   
    }

    const vals = [
        "Confianza", "Honestidad", "Responsabilidad", "Trabajo en equipo", "Respeto", "Lealtad"  
    ]

    const spirit = [
        "Competitivo", "Sin Límites", "Sin miedos", "Apasionado", "Fuerte", "Efectivo", "Energético"
    ]

   

  return (

 <Container fluid className='OurVal' id='nosotros'>
    <Row className='valWrapper '>
    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#Nosotros">
        <Row>
            <Col className='Column' lg={2} sm={4}>
            <ListGroup>
                <ListGroup.Item  action href='#Nosotros'>Nosotros</ListGroup.Item>
                <ListGroup.Item  action href='#Mision'>Misión</ListGroup.Item>
                <ListGroup.Item  action href='#Vision'>Visión</ListGroup.Item>
                <ListGroup.Item  action href='#Valores'>Valores</ListGroup.Item>
                <ListGroup.Item  action href='#Espiritu'>Espíritu</ListGroup.Item>
            </ListGroup>
            </Col>
            <Col className='Column' lg={8} sm={7}>
                <Tab.Content>
                <Tab.Pane eventKey="#Nosotros">
                    <Container className='WrapperMessage'>
                        {/* <Container className='ValueMessage'> */}
                            <p>{OurVal.Nosotros}</p>
                        {/* </Container> */}
                    </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#Mision">
                    <Container className='WrapperMessage'>
                       
                            <p>{OurVal.Mision}</p>
                       
                    </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#Vision">
                    <Container className='WrapperMessage'>
                       
                            <p>{OurVal.Vision}</p>
                       
                    </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#Valores">
                <Container fluid className='WrapperListDots'>
                    <Row>
                    <Col  className="text-md-center text-start">
                        <ul className="list-with-dots">
                        {vals.map((v, index) => (
                            <li key={index}>{v}</li>
                        ))}
                        </ul>
                    </Col>
                    </Row>
                </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#Espiritu">
                <Container className='WrapperListDots'>
                    <Row>
                    <Col className="text-md-center text-start">
                        <ul className="list-with-dots">
                        {spirit.map((s, index) => (
                            <li key={index}>{s}</li>
                        ))}
                        </ul>
                    </Col>
                    </Row>
                </Container>
                </Tab.Pane>
            </Tab.Content>
             
        </Col>
       
       
        </Row>
        </Tab.Container>

        </Row>

 </Container>
        

        

   
  )
}

export default Values