import React from 'react'
import Fondo from '../../components/bkgImg'
import CardContainer from '../../components/Card/CardContainer'
import Header from '../../components/Header'
import Gmap from '../../components/Mapa'
import NavBar from '../../components/Nav/NavBar'
import Support from '../support'

const GIT = () => {
  return (
    <Fondo>
        <Header/>
        <NavBar/>
        <div className='mt-2'>
            <CardContainer>
            <Gmap/>
            </CardContainer>
        </div>
        
        <Support/>
    </Fondo>
    
  )
}

export default GIT