import React, {useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row,Container, Card,Col, Button,Form} from 'react-bootstrap'
import ApiCall from '../../AxiosCalls'
import useAuth from '../Hooks/useAuth'
import Edicion from '../Modals/UserEdit'
import ClientMod from '../Modals/ClientEdit'
import ShowRFQ from '../Modals/QuoteTable'
import TicketShow from '../Modals/TicketsTable'
import useAxiosPrivate from '../Hooks/useAxiosPrivate'



const Admon = () => {
    const Navigate=useNavigate();
    const [usuarios,setUsuarios]=useState(null)
    const [user,setUser]=useState("")
    const [clientes,setClientes]=useState([])
    const [vendedores,setVendedores]=useState([])
    const [openRfqs,setOpenRfqs]=useState([]);
    const [everyTicket,setEveryTicket]=useState([]);
    const [refresh,setRefresh]=useState(false)
    const [closedTickets,setClosedTickets]=useState([])
    const {editUser,setEditUser,editVendor,setEditVendor,
      rfq,showQuotes,setShowQuotes,showTickets,setShowTickets}=useAuth()
    const AxiosPrivate=useAxiosPrivate()
    

    useEffect(()=>{
     CallUsers();
    callRfq();
    callTickets();
    },[])

    

    const CallUsers=async()=>{
      const items= await AxiosPrivate.get("/user/Allusers", {withCredentials:true})
      let users=items.data
      setUsuarios(items.data)
      setClientes(users.filter(u=> u.profile ==='Cliente'))
      setVendedores(users.filter(v=>v.profile ==='Vendedor'))
      return items
  }

  const callRfq=async()=>{
    let arreglo= await ApiCall.AllQuotes()
    let quotes=arreglo.data
    let filtered=quotes.filter(q=>q.CloseStatus === false)
    // console.log(filtered);
    setOpenRfqs(filtered)

  }
  
    const callTickets=async()=>{
      let tickets= await ApiCall.AllTickets()
      .then(res=>res.data)
      .catch(e=>console.log());
      setEveryTicket(tickets.filter(t=>t.CloseStatus === false));
      setClosedTickets(tickets.filter(q=>q.CloseStatus === true))
      setRefresh(!refresh)
    }
   
    const UserDelete=async(userId)=>{
      await ApiCall.UserRemover(userId)
      .then(res=>console.log(res.status))
      .catch((e)=>{console.log(e)})
      setRefresh(!refresh)
    }

    

  return (
    <div>
         <Row>
        <Container fluid className='mt-2' style={{fontSize:'16pt',fontWeight:'bold'}}> Bienvenido a tu página de configuración</Container>
      </Row>
      <Row>  
        <Col md={6}>
        <Container style={{backgroundColor:'transparent'}} className="d-flex justify-content-center">        
        <Card style={{height:'12rem',width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='mt-2 d-block  justify-text-center'>
            <Card.Title >Control de Usuarios</Card.Title>
            <Card.Body>
              { usuarios ? (<>                
                Total de usuarios : {usuarios.length}
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectUser"        
          onChange={(e) => setUser(e.target.value)}
          >
            <option>Selecciona un usuario</option>
       {usuarios.map((c)=>(             
                  <option key={c._id} value={c._id}>{c.FirstName} {c.LastName}</option>
                ))}
          </Form.Select>
            </Container>    
                </>):(<></>)}
            </Card.Body>
            <Card.Footer className='pb-3'>
              <Button  onClick={(e)=>setEditUser(!editUser)} variant='light' style={{background:'#FAB771'}}>Editar</Button> <span className='mx-3'><Button variant='danger' onClick={()=>UserDelete(user)}>Borrar</Button></span>
            </Card.Footer>
          </Card>
          {editUser && (<Edicion User={user}/>)}
          </Container>
          <Container style={{backgroundColor:'transparent'}} className="d-flex justify-content-center">
        <Card style={{height:'12rem',width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='mt-2 d-block justify-text-center'>
            <Card.Title>Control de Clientes</Card.Title>
            <Card.Body>
              { clientes ? (<>
                Clientes Registrados : {clientes.length}
          <Container className=' pt-2 d-flex justify-content-center'>
          <Form.Select 
          id="selectClient"        
          onChange={(e) => setUser(e.target.value)}
          >
            <option>Selecciona un cliente</option>
       {clientes.map((c)=>(             
                  <option key={c._id} value={c._id}>{c.FirstName} {c.LastName}</option>
                ))}
          </Form.Select>
            </Container>    
                </>):(<></>)
              }
              
            </Card.Body>
            <Card.Footer className='pb-3'>
              <Button variant='light' style={{background:'#FAB771'}} onClick={(e)=>setEditVendor(!editVendor)}>Editar</Button> 
            </Card.Footer>
          </Card>
          {editVendor&& (<ClientMod User={user} vendors={vendedores}></ClientMod>)}          
        </Container>
        </Col>
     
        <Col md={6}>
        <Container style={{backgroundColor:'transparent'}} className="d-flex justify-content-center">
        
        <Card style={{height:'12rem', width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='mt-2 d-block  justify-text-center'>
            <Card.Title>Cotizaciones</Card.Title>
            <Card.Body>
              { openRfqs ? ( <Container className=' pt-2 d-flex justify-content-center'>
          Cotizaciones Abiertas : {openRfqs.length}
            </Container>    
                ):(<></>) }
            </Card.Body>
            <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={(e)=>setShowQuotes(!showQuotes)}>Ver Cotizaciones</Button>
            </Card.Footer>
          </Card>
          {showQuotes && (<ShowRFQ quotes={openRfqs} />)}
          </Container>
          <Container style={{backgroundColor:'transparent'}} className="d-flex justify-content-center">
        <Card style={{height:'12rem',width:'38rem',backgroundColor:'#00000050',color:'#FFFFFF'}} className='mt-2 d-block  justify-text-center'>
            <Card.Title>Tickets de soporte</Card.Title>
            <Card.Body> { everyTicket ? ( <Container className=' pt-2 d-flex justify-content-center'>
          Tickets Abiertos : {everyTicket.length}
              <br/>
          Tickets Cerrados: {closedTickets.length}
            </Container>    
                ):(<></>) }</Card.Body>
                  <Card.Footer className='pb-3'>
            <Button variant='light' style={{background:'#FAB771'}} onClick={(e)=>setShowTickets(!showTickets)}>Ver Tickets Abiertos</Button>
            </Card.Footer>
          </Card>
          {showTickets && <TicketShow alltickets={everyTicket} doneTickets={closedTickets}/>}
                 
        </Container>
        </Col>
      </Row>
      <Row>
        <Container fluid>
          <Button variant='secondary' style={{width:'12rem',marginTop:'8rem'}} onClick={()=>Navigate('/home',{replace:true})} >Salir</Button>
        </Container>
      </Row>
    
    </div>
  )
}

export default Admon
