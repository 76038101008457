import React, {useEffect, useState} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay,FreeMode,Thumbs,Navigation} from "swiper";
import 'swiper/css/thumbs'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import '../Swiper/styleswiper.css'
import ApiCall from '../../AxiosCalls';

const Slider = () => {
  const [thumbsSwiper, setThumbsSwiper]=useState(null);
const [contents,setContents]=useState([]);

  useEffect(()=>{
    const CarrImages=async()=>{
      const localArray= await ApiCall.GetAllCust()
      .then(res=>res.data)
      .catch(err=>console.log(err))
      setContents(localArray)
  }
  CarrImages();
  },[contents])

  return (
<div className='swiper'>
    <div className='swiper-wrapper'>
      <div className='swiper-slide'>
        <div className='swiper-slide-transform'>
          <div className='slide-Wrapper'>
        <Swiper
        // Navigation={true}
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#fff",
        }}
        
        effect={"Thubs"}
        grabCursor={true}
        centeredSlides={true}
        centeredSlidesBounds={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        // slidesPerView={window.innerWidth<786 ? 1: "auto"}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          // when window width is >= 740px
          740: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 10
          },
       
              // when window width is >= 880px
              853: {
                slidesPerView: 5,
                spaceBetween: 60
              }
        }} 
        breakpointsBase='container'
      
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        cssMode={true}
        thumbs={{ swiper: thumbsSwiper }}
        navigation={true}
        modules={[Autoplay, FreeMode, Thumbs, Navigation]}
       createElements={true}
        // className="mySwiper2"
      >       
       { contents===null ? "" : contents.map(({picture,_id})=>{
        return(
          <SwiperSlide key={_id} >
              <img src={picture} alt={_id} />
              </SwiperSlide>
        )
       })}
      </Swiper>    
         </div>
        </div>
      </div>
    </div>
  </div>






  )
}

export default Slider