import React from "react";
import Fondo from "../../components/bkgImg";
import Tarjeta from "../../components/Card";
import NoAuthorized from "../../components/Forms/NoAuthorized";

const Unauthorized = () => {





  return (
    <Fondo>
        <div className="sign_form">
            <Tarjeta>
                <NoAuthorized/>
            </Tarjeta>

        </div>
    </Fondo>

  )
}

export default Unauthorized