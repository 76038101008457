import React from 'react'
import {Row,Container} from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'
import './Style.css'
// import CardProd from '../ProductCard/CardProd'
import SolCard from '../ProductCard/SolCard'

const Solucion = (props) => {
    const {solutions,productDispatch,productState:{byName}}=useAuth()

//  const SolutionIndex = ()=>{
//   const ChosenSolution =props.children
//   const NewSolution= 
//  }

    const TransProducts = ()=>{
      const SelectedSolution= props.children
      const NewPack=Selection(SelectedSolution)
      let sortedProducts=solutions
  // Creating filter iterable based on another array getting Name form reducer and Cateory for original Array
          sortedProducts=solutions.filter(x=>{
                  return NewPack.find(c=>c.Name === x.Category)
              });
     
      return sortedProducts
  }

  const Selection=(Item)=>{
    let pack=[]
    switch (Item) {
      case "Bombeo Solar":
          pack=[{Name:"Bombas"},{Name:"Accesorios y cableado"},{Name:"Estructura"},{Name:"Módulos Fotovoltaicos"},{Name:"Protecciones Electricas"}]
        break;
        case "Sistemas de Interconexión a la Red":
          pack=[{Name:"Baterías"},{Name:"Accesorios y cableado"},{Name:"Estructura"},{Name:"Inversores - Interconexión"},{Name:"Módulos Fotovoltaicos"},{Name:"Protecciones Electricas"}]
        break;
        case "Sistemas Aislados a la Red":
          pack=[{Name:"Baterías"},{Name:"Accesorios y cableado"},{Name:"Estructura"},{Name:"Inversores - Aislados"},{Name:"Módulos Fotovoltaicos"},{Name:"Protecciones Electricas"},{Name:"Controladores"}]
        break;
        case "Refrigeradores":
          pack=[{Name:"Refrigeradores"}]
        break;
        case "Iluminación":
          pack=[{Name:"Iluminación"}]
        break;
        case "Calentadores Solares":
          pack=[{Name:"Calentadores"}]
        break;
      default:
        break;
    }
    return pack
  }

    
  return (
    <div>
    <Row>
    <Container>
        <section style={{color:'#2c2c2c',colorBackground:'#000000FF',fontSize:'18pt'}}>Te presentamos todos los productos relacionados con:<br></br> {props.children}</section>
    </Container>
    </Row>
    <Row className='ProdGrid'>
        {TransProducts().map(({_id,Category,Picture,DescripcionCorta})=>{
                    return(
                    <SolCard 
                        Key={_id}
                        category={Category}
                        image={Picture}
                        // pdctName={Name}
                        desc={DescripcionCorta}
                        // descLg={DescripcionLarga}
                        // marca={Brand}
                        // techdata={Datasheet}
                         />
                    )
                })}
    </Row>
</div>
  )
}

export default Solucion