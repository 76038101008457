import React, {useState,useEffect} from 'react'
import { Card } from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'
import PromoPop from '../Popovers/promoPop';
import ApiCall from '../../AxiosCalls';
import useLogOut from '../Hooks/useLogOut';

const PromoCard = (props) => {
    const [magnifier,setMagnifier]=useState(false)
    const [target,setTarget]=useState(null)
    const {products,cartDispatch,auth,currentuser}=useAuth(); 
    const SignOut=useLogOut()

    useEffect(()=>{
      !currentuser && SignOut()
    },[])

    const HandleAsk=(e)=>{
      e.preventDefault()
      alert("Por favor Inicia sesión para agregar productos al carrito")
    }

    const HandleCart=(Id)=>{
      console.log("identifier: ",Id);
      console.log(products);
      let filtering=products.filter(product=>product._id === Id)
    // this auxiliary array is needed since we are getting only one item from the DB 
      let toCart=filtering[0] 
    // console.log(toCart._id, toCart.Brand, toCart.Name);
    
      cartDispatch({
        type:"ADD_PRODUCT",
        payload:{
          Id:toCart._id,
          Brand:toCart.Brand,
          Name:toCart.Name,
          Picture:toCart.Picture
        }
      })
    }

    const HandleClear=async(ID)=>{
      await ApiCall.RemovePromo(ID)
      .then(res=>console.log(res))
      .catch(e=>console.log(e))
    }
 
console.log(auth);
  return (
   
        <Card style={{width: '20rem', margin:'0px 20px 55px 0px'}}>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Img variant='top' style={{ height:'10rem', width:'auto'}} src={props.image} />
                {/* <Card.Subtitle className='mt-2'>{props.desc}</Card.Subtitle> */}
            </Card.Body>
            <Card.Footer className='cardFoot mb-0'>
            <span className='me-4 '>
            <a href onClick={auth?.isAuthenticated ? ((e)=>HandleCart(props.RefId)):((e)=>HandleAsk(e))}>
              <i className="fa-duotone fa-cart-plus fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#f2a854"}} ></i></a>
            </span>
                    <span className='me-3 ms-1'>
                    <a href name={props.title} onClick={(e)=>{setMagnifier(!magnifier);setTarget(e.target);}} >
                      <i className="fa-duotone fa-glasses fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#f2a854"}} ></i></a>
                    </span>
                    {auth?.isAuthenticated && auth.roles.includes(2023) ? (
                  <span className=' ms-4'>
                    <a href name='ClearPromo' onClick={()=>HandleClear(props.Key)}>
                      <i className="fa-duotone fa-trash-can fa-2xl" style={{"--fa-primary-color": "#23429f", "--fa-secondary-color": "#f2a854"}}></i>
                    </a>

                  </span>
                    ):(<></>)


                    }
                    
                </Card.Footer>
                
                 
              
                {magnifier && <PromoPop title={props.title} target={target} img={props.image}/>}
        </Card>
  )
}

export default PromoCard