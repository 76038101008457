import React from 'react'
import Admon from '../../components/Admon'

import Fondo from '../../components/bkgImg'


const Admin = () => {
  
  return (
    <Fondo>
     <Admon/>


    </Fondo>


  )
}

export default Admin