import React,{useState,useRef, useEffect} from 'react'
import { Container,Button,Overlay,Popover ,ListGroup, ListGroupItem,Navbar,Nav,NavDropdownDropdown, NavLink  } from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import useLogOut from '../Hooks/useLogOut'
import ChangeSettings from './changeSettings'
import useAuth from '../Hooks/useAuth'
import useAxiosPrivate from '../Hooks/useAxiosPrivate'


const ProfileControl = (props) => {
  const Navigate=useNavigate();
  const [callsettings,setCallsettings]=useState(false)
  const [target,setTarget]=useState(null)
    const [show,setShow]=useState(true)
    const ref = useRef(null);
    const signOut=useLogOut();
    const {profilemodal,auth,setPersist}=useAuth()
    const myaxios=useAxiosPrivate()

    
    
    const logoff=async()=>{
      setPersist(false);
      setShow(false);
      await signOut(); 
      Navigate('/home',{replace:true})
      }

      //listening to profilemodal and when it is true then hide the popout
      useEffect(()=>{
        profilemodal && setShow(false)
      },[profilemodal])

     
  return (
    <div ref={ref} style={{width:'10rem',backgroundColor:'pink'}}>
    
      <Overlay
        show={show}
        target={props.target} // i linked it to the line that sets the command on the previous component 
        placement="bottom"
        container={ref}
        containerPadding={20}
        
      >
        <Popover id="popover-contained" style={{maxWidth:'15rem',backgroundColor:'#FFC32E'}}>
          <Popover.Header as="h3" style={{color:'#2D2D2D', fontSize:'14pt'}}>Hola!  {props.user.FirstName}</Popover.Header>
          <Popover.Body>
            <ListGroup variant='flush' className=''>
                <ListGroupItem style={{color:'#2d2d2d',fontSize:'14pt'}}>Perfil: {props.user.profile}</ListGroupItem>
                <Nav defaultActiveKey="/home" className="flex-column">
                  <Nav.Link eventKey="disabled" style={{fontSize:'12px'}} disabled>Accesos Rápidos</Nav.Link>
                

        {auth.roles?.find(role=> [2023,5150].includes(role))
          ?  <div>
            <Nav.Link eventKey="link-2" onClick={(e)=>{e.preventDefault();
            Navigate('/marketing',{replace:true})
            }}>Marketing</Nav.Link>
            <Nav.Link eventKey="link-2" onClick={(e)=>{e.preventDefault();
              Navigate('/catedit',{replace:true})
              }}>Editar Catálogo</Nav.Link>
              <Nav.Link eventKey="link-2" onClick={(e)=>{e.preventDefault();
              Navigate('/apperance',{replace:true})
              }}>Editar carousel</Nav.Link>
              </div>
            
          : <></>
          }

        {auth.roles?.find(role=> [1978,5150].includes(role))
          ?  <Nav.Link eventKey="link-2" onClick={(e)=>{e.preventDefault();
            Navigate('/helpdesk',{replace:true})
            }}>Soporte</Nav.Link>
          : <></>
          }

        {auth.roles?.find(role=> [5150].includes(role))
            ?  <Nav.Link eventKey="link-2.5" onClick={(e)=>{e.preventDefault();
              Navigate('/Admin',{replace:true})
              }}>Administración</Nav.Link>
            : <></>
            }

        {auth.roles?.find(role=> [1950].includes(role))
                  ?  <Nav.Link eventKey="link-2.5" onClick={(e)=>{e.preventDefault();
                    Navigate('/Supply',{replace:true})
                    }}>Mi Portal</Nav.Link>
                  : <></>
                  }

        {auth.roles?.find(role=> [1998].includes(role))
                  ?  <Nav.Link eventKey="link-2.5" onClick={(e)=>{e.preventDefault();
                    Navigate('/ventas',{replace:true})
                    }}>Ventas</Nav.Link>
                  : <></>
                  }

                  <Nav.Link eventKey="link-4" onClick={(e)=>{
                    e.preventDefault();
                    setCallsettings(!callsettings);
                    setTarget(e.target)
                  }} >Ajustes</Nav.Link>
    </Nav>
            </ListGroup>
            {callsettings && <ChangeSettings target={target}/>}
        
            <Container className='d-flex justify-content-center mt-2'>
            <Button variant='dark' onClick={logoff}>Cerrar Sesión</Button>
<br/>
{/* <Button variant='dark' onClick={()=>getUsers()}>Test Users</Button> */}

            </Container>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
    
  )
}

export default ProfileControl