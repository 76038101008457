import React, {useRef,useState} from 'react'
import Fondo from '../../components/bkgImg'
import Tarjeta from '../../components/Card'
import Askreset from '../../components/Forms/Askreset'

const Pswrst = () => {


  return (
    <Fondo>
    <div className="sign_form">
      <Tarjeta>
        
      <Askreset/>

      </Tarjeta>


    </div>
    
    {/* <Support/> */}
  </Fondo>
  )
}

export default Pswrst